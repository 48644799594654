import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import { FaChartBar, FaHome, FaSchool } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { authentication, firestore } from "../firebase";
import {UserContext} from "../realm/user.context";
import {loadProfile} from "../realm/graphqlQueries";
import "./Sidebar.css";

const Sidebar = (props) => {
  const [user, setUser] = useState("student");
  const [school, setSchool] = useState("");
  const [district, setDistrict] = useState("");
  const { realmUser, logInRealmUser, logOutRealmUser} = useContext(UserContext);

  const location = useLocation();

  async function getUserData() {
    const idToken = await user.getIdToken(/* forceRefresh */ true)
    let realmResp = null
    if(realmUser===null){
      const authedUser = await logInRealmUser(idToken)
      realmResp = await loadProfile(authentication.currentUser.email,authedUser)
    }else{
      realmResp = await loadProfile(authentication.currentUser.email,realmUser)
    }
    console.log(realmResp)
    if(realmResp.user.school)setSchool(realmResp.user.school);
    if(realmResp.user.district)setDistrict(realmResp.user.district);
    setUser(realmResp.user.user);
  }
  useEffect(() => {
    if(props.user.user.school){
      setSchool(props.user.user.school)
    }
    if(props.user.user.district){
      setDistrict(props.user.user.district)
    }
    if(props.user.user.user){
      setUser(props.user.user.user)
    }
    //getUserData();
    console.log(props.user.user.user)
  }, []);
  return (
    <div className="sidebar">
      <div className="sidebarcolumn">
        <div className="sidebarrow">
        
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              className="sidebarlogo"
              src="images/123WellnessLogo-01.png"
              alt="1-2-3 Wellness"
            loading="lazy"/>
          </Link>
          
          {user === "student" && (
            <div className="alignMenuOptions">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="icontextrowstudent">
                  <div className="styleiconstudenthome">
                    <FaHome></FaHome>
                  </div>
                  {location.pathname === "/" ? (
                    <h1
                      className="icontexthome"
                      style={{ textDecoration: "underline" }}
                    >
                      Home
                    </h1>
                  ) : (
                    <h1 className="icontexthome">Home</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/profilescreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowstudent">
                  <div className="styleiconstudent">
                    <FaUser></FaUser>
                  </div>
                  {location.pathname === "/profilescreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      My Story
                    </h1>
                  ) : (
                    <h1 className="icontext">My Story</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/optionsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowstudent">
                  <div className="styleiconstudent">
                    <FaCog></FaCog>
                  </div>
                  {location.pathname === "/optionsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Options
                    </h1>
                  ) : (
                    <h1 className="icontext">Options</h1>
                  )}{" "}
                </div>
              </Link>
            </div>
          )}{" "}
          {user === "teacher" && (
            <div className="alignMenuOptions">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
              >
                <div className="icontextrowteacher">
                  <div className="styleiconteacherhome">
                    <FaHome></FaHome>
                  </div>
                  {location.pathname === "/" ? (
                    <h1
                      className="icontexthome"
                      style={{ textDecoration: "underline" }}
                    >
                      Home
                    </h1>
                  ) : (
                    <h1 className="icontexthome">Home</h1>
                  )}
                </div>
              </Link>
              <Link to="/profilescreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaUser></FaUser>
                  </div>
                  {location.pathname === "/profilescreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      My Story
                    </h1>
                  ) : (
                    <h1 className="icontext">My Story</h1>
                  )}
                </div>
              </Link>
              <Link to="/classscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaBook></FaBook>
                  </div>
                  {location.pathname === "/classscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Students
                    </h1>
                  ) : (
                    <h1 className="icontext">Students</h1>
                  )}
                </div>
              </Link>
              <Link to="/optionsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowteacher">
                  <div className="styleiconteacher">
                    <FaCog></FaCog>
                  </div>
                  {location.pathname === "/optionsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Options
                    </h1>
                  ) : (
                    <h1 className="icontext">Options</h1>
                  )}
                </div>
              </Link>
            </div>
          )}{" "}
          {user === "admin" && (
            <div className="alignMenuOptions">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin" >
                  <div className="styleiconadminhome">
                    <FaHome></FaHome>
                  </div>
                  {location.pathname === "/" ? (
                    <h1
                      className="icontexthome"
                      style={{ textDecoration: "underline" }}
                    >
                      Home
                    </h1>
                  ) : (
                    <h1 className="icontexthome">Home</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/profilescreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaUser></FaUser>
                  </div>
                  {location.pathname === "/profilescreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      My Story
                    </h1>
                  ) : (
                    <h1 className="icontext">My Story</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/datatrends" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  {location.pathname === "/datatrends" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Data
                    </h1>
                  ) : (
                    <h1 className="icontext">Data</h1>
                  )}
                </div>
              </Link>
              <Link to="/classscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaBook></FaBook>
                  </div>
                  {location.pathname === "/classscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Students
                    </h1>
                  ) : (
                    <h1 className="icontext">Students</h1>
                  )}{" "}
                </div>
              </Link>

              <Link to="/optionsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaCog></FaCog>
                  </div>
                  {location.pathname === "/optionsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Options
                    </h1>
                  ) : (
                    <h1 className="icontext">Options</h1>
                  )}
                </div>
              </Link>
            </div>
          )}
          {user === "superadmin" && (
            <div className="alignMenuOptions">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadminhome">
                    <FaHome></FaHome>
                  </div>
                  {location.pathname === "/" ? (
                    <h1
                      className="icontexthome"
                      style={{ textDecoration: "underline" }}
                    >
                      Home
                    </h1>
                  ) : (
                    <h1 className="icontexthome">Home</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/profilescreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaUser></FaUser>
                  </div>
                  {location.pathname === "/profilescreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      My Story
                    </h1>
                  ) : (
                    <h1 className="icontext">My Story</h1>
                  )}{" "}
                </div>
              </Link>
              <Link
                to="/superadmindatatrends"
                style={{ textDecoration: "none" }}
              >
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaChartBar></FaChartBar>
                  </div>
                  {location.pathname === "/superadmindatatrends" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Data
                    </h1>
                  ) : (
                    <h1 className="icontext">Data</h1>
                  )}
                </div>
              </Link>
              <Link to="/schoolsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaSchool></FaSchool>
                  </div>
                  {location.pathname === "/schoolsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Schools
                    </h1>
                  ) : (
                    <h1 className="icontext">Schools</h1>
                  )}{" "}
                </div>
              </Link>

              <Link to="/optionsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowadmin">
                  <div className="styleiconadmin">
                    <FaCog></FaCog>
                  </div>
                  {location.pathname === "/optionsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Options
                    </h1>
                  ) : (
                    <h1 className="icontext">Options</h1>
                  )}
                </div>
              </Link>
            </div>
          )}
          {user === "123wellness" && (
            <div className="alignMenuOptions">
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  className="icontextrowwellness"
                >
                  <div className="styleiconwellnesshome">
                    <FaHome></FaHome>
                  </div>
                  {location.pathname === "/" ? (
                    <h1
                      className="icontexthome"
                      style={{ textDecoration: "underline" }}
                    >
                      Home
                    </h1>
                  ) : (
                    <h1 className="icontexthome">Home</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/profilescreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaUser></FaUser>
                  </div>
                  {location.pathname === "/profilescreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      My Story
                    </h1>
                  ) : (
                    <h1 className="icontext">My Story</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/wellnessstudents" style={{ textDecoration: "none" }}>
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaBook></FaBook>
                  </div>
                  {location.pathname === "/wellnessstudents" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Students
                    </h1>
                  ) : (
                    <h1 className="icontext">Students</h1>
                  )}{" "}
                </div>
              </Link>
              <Link to="/wellnessdatatrends" style={{ textDecoration: "none" }}>
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaChartBar></FaChartBar>
                  </div>
                  {location.pathname === "/wellnessdatatrends" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Data
                    </h1>
                  ) : (
                    <h1 className="icontext">Data</h1>
                  )}
                </div>
              </Link>
              <Link
                to="/wellnessadministrator"
                style={{ textDecoration: "none" }}
              >
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaSchool></FaSchool>
                  </div>
                  {location.pathname === "/wellnessadministrator" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Districts
                    </h1>
                  ) : (
                    <h1 className="icontext">Districts</h1>
                  )}{" "}
                </div>
              </Link>

              <Link to="/optionsscreen" style={{ textDecoration: "none" }}>
                <div className="icontextrowwellness">
                  <div className="styleiconwellness">
                    <FaCog></FaCog>
                  </div>
                  {location.pathname === "/optionsscreen" ? (
                    <h1
                      className="icontext"
                      style={{ textDecoration: "underline" }}
                    >
                      Options
                    </h1>
                  ) : (
                    <h1 className="icontext">Options</h1>
                  )}
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="schoolanddistrict">
          <div>{school}</div>
            <div>{district}</div>
          </div>
        <div className="patentpending">Patent Pending</div>
        <div className="growthwell">© Growthwell LLC 2023</div>
      </div>
    </div>
  );
};

export default Sidebar;
