import React, { useEffect, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./ProfileScreen.css";
import "./ProfileCalendar.css";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import { Popover } from 'react-tiny-popover'
import { useContext } from "react";
import { UserContext } from "../../realm/user.context";
import { getWellnessDataByUserID, updateWellnessHelpfulMutation } from "../../realm/graphqlQueries";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
 


const ProfileScreen = (props) => {
  const navigate = useNavigate();
  const barColors = ["#5b9cd6", "#4cc68d", "#bd92e4"];
  const { realmUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [wellnessList, setWellnessList] = useState([]);
  const localizer = momentLocalizer(moment);
  const [state, setState] = useState({
    favourites: false,
    sad: true,
    tired: true,
    lonely: true,
    happy: true,
    grateful: true,
    calm: true,
    stressed: true,
    scared: true,
    angry: true,
  });
  const {
    favourites,
    sad,
    tired,
    lonely,
    happy,
    grateful,
    calm,
    stressed,
    scared,
    angry,
  } = state;

  useEffect(() => {
    fetchAllData(props.user.user, realmUser);
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeBarGraphFilter(filterName)
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellnessList])

 
  const [filterName, setFilterName] = useState("week");
  const [filterAmount, setFilterAmount] = useState(moment.duration(7, "days"));

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

 

  var [feeling, setFeeling] = useState("select")

  const tempdata = [
    {
      name: "sad",
      count: 0,
    },
    {
      name: "tired",
      count: 0,
    },
    {
      name: "lonely",
      count: 0,
    },
    {
      name: "happy",
      count: 0,
    },
    {
      name: "grateful",
      count: 0,
    },
    {
      name: "calm",
      count: 0,
    },
    {
      name: "stressed",
      count: 0,
    },
    {
      name: "scared",
      count: 0,
    },
    {
      name: "angry",
      count: 0,
    },
  ];
  const [studentData, setStudentData] = useState([]);
  const [barGraphData, setBarGraphData] = useState(tempdata);
  const [tally, setTally] = useState(0);

  function calculateTotal(dict) {
    var testCount = 0
    Object.values(dict).forEach(value => {testCount += value})
    return testCount;
  }

  function gatherWellnessPractices(feeling, time) {

    setFilterName(time)
    var filter = moment.duration(7, "days");
    if (time === "day") {
      filter = moment.duration(24, "hours");
    } else if (time === "week") {
      filter = moment.duration(7, "days");
    } else if (time === "month") {
      filter = moment.duration(30, "days");
    } else if (time === "3 months") {
      filter = moment.duration(90, "days");
    } else if (time === "6 months") {
      filter = moment.duration(182, "days");
    } else if (time === "year") {
      filter = moment.duration(365, "days");
    }

    var emotionDict = {
      "sad": {},
      "tired": {},
      "lonely": {},
      "happy": {},
      "grateful": {},
      "calm": {},
      "stressed": {},
      "scared": {},
      "angry": {}
    }
    studentData.filter(data => new Date(data.date).getTime() >= moment().subtract(filter).valueOf());
    studentData.forEach((datapoint) => {
      let feeling = datapoint.emotion
      let helped = datapoint.helpful
      let action = datapoint.action
  
      if (helped) {
        if (!(action in emotionDict[feeling])) {
          emotionDict[feeling][action] = 0
        }
        emotionDict[feeling][action]++;
      }
    });
    var feelingList;
    setFeeling(feeling)
    if (feeling === "select") {
      feelingList = [];
    } else {
      feelingList = Array.from(Object.keys(emotionDict[feeling]));
    }
    
    if (feelingList.length > 3) {
      let objectList = Object.keys(emotionDict[feeling]).sort(function(a,b){return emotionDict[feeling][b] - emotionDict[feeling][a]})
      setWellnessList(objectList.slice(0, 3))
    } else {
      setWellnessList(feelingList)
    }
  }

  async function fetchAllData(user, realmUser) {
    setTally(0);
    setLoading(true)
    const resp = await getWellnessDataByUserID(user._id, realmUser)
    let bargraphdata;
    let dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    }
    
    resp
    .filter((data) => new Date(data.date).getTime() >= moment().subtract(moment.duration(7, "days")).valueOf())
    .forEach((datapoint) => {
      dict[datapoint.emotion] += 1
    });

    bargraphdata = Object.keys(dict).map((key) => ({
      name: key,
      count: dict[key],
    }));
    setTally(calculateTotal(dict));
    setBarGraphData(bargraphdata);
    setStudentData(resp);
    setLoading(false)
  }

  function getDate(data) {
    return moment(data).format("l");
  }
  function getTime(data) {
    return moment(data).format("LT");
  }
  function getColor(emotion) {
    if (emotion === "happy" || emotion === "grateful" || emotion === "calm")
      return "#A5E2C6";
    else if (
      emotion === "stressed" ||
      emotion === "scared" ||
      emotion === "angry"
    )
      return "#DEC8F1";
    else if (emotion === "sad" || emotion === "tired" || emotion === "lonely")
      return "#ADCDEA";
  }
  function getImage(emotion) {
    return "../images/emotions/" + emotion + ".png";
  }
  function getHeart(helpful) {
    if (helpful) return <FaHeart style={{ fontSize: "1.3vmax" }}></FaHeart>;
    else return <FaRegHeart style={{ fontSize: "1.3vmax" }}></FaRegHeart>;
  }
  function changeBarGraphFilter(time) {
    setTally(0);
    var filter = moment.duration(7, "days");
    if (time === "day") {
      filter = moment.duration(24, "hours");
    } else if (time === "week") {
      filter = moment.duration(7, "days");
    } else if (time === "month") {
      filter = moment.duration(30, "days");
    } else if (time === "3 months") {
      filter = moment.duration(90, "days");
    } else if (time === "6 months") {
      filter = moment.duration(182, "days");
    } else if (time === "year") {
      filter = moment.duration(365, "days");
    }
    var bargraphdata;
    var dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    studentData
      .filter((data) => new Date(data.date).getTime() >= moment().subtract(filter).valueOf())
      .forEach((datapoint) => {
        dict[datapoint.emotion] += 1; setTally(tally => tally + 1);
      });
    bargraphdata = Object.keys(dict).map((key) => ({
      name: key,
      count: dict[key],
    }));

    setFilterAmount(filter);
    setFilterName(time);
    setBarGraphData(bargraphdata);
  }
  const events = studentData.map((data) => {
    return {
      start: moment(data.date).toDate(),
      end: moment(data.date).add(1, "minutes").toDate(),
      title: (
        <img
          className="calendarimg"
          src={getImage(data.emotion)}
          alt="...loading"
        ></img>
      ),
    };
  });

  async function helpful(data) {
    data.helpful = !data.helpful;
    try {
      // Call the GraphQL mutation to update the 'helpful' field
      console.log(props.user.user._id)
      const resp = await updateWellnessHelpfulMutation(data._id, data.helpful, realmUser);
      console.log(resp)
      if (resp && resp.updateOneWellness && resp.updateOneWellness._id) {
        console.log("Success!");
        navigate("/profilescreen");
      } else {
        console.error("Update failed!");
      }
    } catch (error) {
      console.error("Failed!", error);
    }
    fetchAllData(props.user.user, realmUser);
    setStudentData(studentData);
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const Cal = () => (
    <div className="profilecalendar">
      <div  style={{textAlign: 'center'}}>
        <div className="titletextinside" style={{marginLeft: '0px'}}> My Month</div>
        <div className="titledescription" style={{marginLeft: '0px'}}>
            This calendar shows emotions you’ve felt this month
        </div>
      </div>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        className='calendarsizer'
        // style={{ height: "85vh", width: "55vw" }}
      />
    </div>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function checkFavorites(emotion) {
    if (state.favourites === false) {
      return true;
    } else {
      return emotion;
    }
  }
  const CustomTooltip = ({ active, payload, label }) => {
    var total = 0;
    barGraphData.forEach(data => {
      total += data.count
    })
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`} </p>
          <p className="bluelabel">{`count: ${payload[0].value}`} </p>
          <p className="bluelabel">{`percentage : ${Math.round(payload[0].value/total*100)}%`}</p>
          <p className="bluelabel">{`total : ${total}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div
    className='backgroundlayer'
      // style={{
      //   // display: "flex",
      //   // flexDirection: "row",
      //   backgroundColor: "#f8f2ee",
      //   height: '100vh'
      // }}
    >
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
      {/* in the middle of making onselect work for this try using onchange */}
      {/* <Sidebar></Sidebar> */}
      <div className="profileoutercolumn">
        {/* <div className="titletext">Emotions Graph</div>
        <div className="titledescription">
          This graph shows emotions you have been feeling
        </div> */}
        <div className="practicedwellness" style={{marginLeft: '8vw'}}>
            Congratulations,{" "}
            you've practiced wellness <span className="greenlettersdata">{tally} times</span> in
            the last <span className="greenlettersdata">{filterName}</span>!
        </div>
        
        <div className="bargraph" style={{marginBottom: '2vh'}}>
          <div style={{display: 'flex'}}>
            <select
              className="filterprofile"
              onChange={(e) => changeBarGraphFilter(e.target.value)}
              value={filterName}
            >
              <option value={"day"}>Today (24 Hours)</option>
              <option value={"week"}>This Week (7 days)</option>
              <option value={"month"}>This Month (30 days)</option>
              <option value={"3 months"}>Last 3 Months</option>
              <option value={"6 months"}>Last 6 Months</option>
              <option value={"year"}>Last Year</option>
            </select>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div className="titletextinside">My Emotions</div>
              <div className="titledescription">
                This graph shows emotions you have been feeling
              </div>
            </div>
          </div>
          {" "}
          <ResponsiveContainer width="90%" height="80%" >
            <BarChart
              style={{ marginLeft: "3vw", marginTop: "6vh", fontSize: "1.2vw" }}
              data={barGraphData}
            >
              <Tooltip content={<CustomTooltip />} cursor={{ fill: "lightgray", fillOpacity: "30%" }}  />
              <Bar barSize={50} dataKey="count" fill="#5b9cd6">
                {barGraphData.map((entry, index) => (
                  <Cell key={index} fill={barColors[Math.floor(index / 3)]} />
                ))}
              </Bar>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="name"
                minTickGap={-2}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>{" "}

        {/* <div className="titletext">Wellness History</div>
        <div className="titledescription">
          Your wellness history shows your emotions and which wellness practices
          have been most helpful
        </div> */}

        <div className="wellnessSuccess" style={{marginTop: '6vh', marginBottom: '2vh'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div className="titletextinside" style={{marginLeft: '0px'}}>My Wellness Success</div>
            <div className="titledescription" style={{marginLeft: '0px', width: '60%', textAlign: 'center'}}>
            This sentence lets you see which wellness practices help you the most when you feel different emotions
            </div>
          </div>
          <div className="practicedwellness" style={{width: '90%'}}>
            <span>When I have felt</span>
            <select className="filterprofile" onChange={(e) => {gatherWellnessPractices(e.target.value, filterName)}} value={feeling}>
              <option value={"select"}>Select</option>
              <option value={"sad"}>Sad</option>
              <option value={"tired"}>Tired</option>
              <option value={"lonely"}>Lonely</option>
              <option value={"happy"}>Happy</option>
              <option value={"grateful"}>Grateful</option>
              <option value={"calm"}>Calm</option>
              <option value={"stressed"}>Stressed</option>
              <option value={"scared"}>Scared</option>
              <option value={"angry"}>Angry</option>
            </select>
            {(filterName === "3 months" || filterName === "6 months" || filterName === "year" ) && <span style={{marginLeft: '2vw'}}> the</span>}
            <select className="filterprofile" onChange={(e) => {gatherWellnessPractices(feeling, e.target.value); changeBarGraphFilter(e.target.value)}} value={filterName}>
              <option value={"day"}>Today (24 Hours)</option>
              <option value={"week"}>This Week (7 days)</option>
              <option value={"month"}>This Month (30 days)</option>
              <option value={"3 months"}>Last 3 Months</option>
              <option value={"6 months"}>Last 6 Months</option>
              <option value={"year"}>Last Year</option>
            </select>
            <span>, wellness practices that are helping build my wellness include {wellnessList.map((activity, key) => (<div className="greenlettersdata" key={key}>{activity}</div>))}</span>
          </div>
        </div>

        <div className="profilecolumn" style={{marginTop: '6vh'}}>
          <div className="dropdownrow" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <select
              className="filterprofile"
              onChange={(e) => changeBarGraphFilter(e.target.value)}
              value={filterName}
            >
              <option value={"day"}>Today (24 Hours)</option>
              <option value={"week"}>This Week (7 days)</option>
              <option value={"month"}>This Month (30 days)</option>
              <option value={"3 months"}>Last 3 Months</option>
              <option value={"6 months"}>Last 6 Months</option>
              <option value={"year"}>Last Year</option>
            </select>
            <div style={{textAlign: 'center', marginLeft: "2vw", marginRight: '2vw'}}>
              <div className="titletextinside" style={{marginLeft: '0px'}}>My Wellness History</div>
              <div className="titledescription" style={{marginLeft: '0px'}}>
              Your wellness history shows your emotions and wellness practices used over time
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2vh', marginRight: '2vw'}}>
              <button className="demo-customized-button" onClick={handleClick} style={{marginLeft: '0px'}}>
                Filter Options
              </button>
              <Popover
                isOpen={isPopoverOpen}
                positions={['top', 'right', 'left', 'bottom']}
                padding={10}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={
                    <div
                      style={{ backgroundColor: 'white', padding: '20px', boxShadow: '0px 0px 2px black', borderRadius: '5px', marginRight: '20px', width: '30vw'}}
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                      <div>
                        Use this filter to see every wellness practice you've tried
                        and every emotion you've felt with 1-2-3 Wellness
                      </div>
                      <img alt="info-icon" src="/HeartChecked.png" />
                      <div>
                        Selecting the shaded heart option shows times 
                        when the wellness practice was helpful 
                      </div>
                    </div>
                  }
              >
                <div className="questionMark" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>?</div>
              </Popover>
            </div>
            
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={favourites}
                        onChange={handleChange}
                        name="favourites"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <FaHeart
                          // src="images/emotions/angry.png"
                          className="filterlabelsimg"
                          // style={{ width: "2vw", marginRight: "5px" }}
                        />
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sad}
                        onChange={handleChange}
                        name="sad"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Sad</span>
                          <img
                            alt="sad-icon"
                            src="images/emotions/sad.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tired}
                        onChange={handleChange}
                        name="tired"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Tired</span>
                          <img
                            alt="tired-icon"
                            src="images/emotions/tired.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lonely}
                        onChange={handleChange}
                        name="lonely"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Lonely</span>
                          <img
                            alt="lonely-icon"
                            src="images/emotions/lonely.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={happy}
                        onChange={handleChange}
                        name="happy"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Happy</span>
                          <img
                            alt="happy-icon"
                            src="images/emotions/happy.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={grateful}
                        onChange={handleChange}
                        name="grateful"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Grateful</span>
                          <img
                            alt="grateful-icon"
                            src="images/emotions/grateful.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={calm}
                        onChange={handleChange}
                        name="calm"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Calm</span>
                          <img
                            alt="calm-icon"
                            src="images/emotions/calm.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stressed}
                        onChange={handleChange}
                        name="stressed"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Stressed</span>
                          <img
                            alt="stressed-icon"
                            src="images/emotions/stressed.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={scared}
                        onChange={handleChange}
                        name="scared"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Scared</span>
                          <img
                            alt="scared-icon"
                            src="images/emotions/scared.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={angry}
                        onChange={handleChange}
                        name="angry"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Angry</span>
                          <img
                            alt="angry-icon"
                            src="images/emotions/angry.png"
                            className='filterlabelsimg'
                          />
                        </div>
                        
                      </>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Menu>
          </div>
          <div className='wellnesspoints'>
          {studentData
            .sort((a, b) => b.date - a.date)
            .filter(
              (data) =>
                new Date(data.date).getTime() >= moment().subtract(filterAmount).valueOf() &&
                state[data.emotion] === true &&
                checkFavorites(data.helpful)
            )
            .map((data) => (
              <div
                className="historybox"
                key={data.date}
                style={{ backgroundColor: getColor(data.emotion) }}
              >
                <img
                  alt="profile-icon"
                  className="profileimg"
                  src={getImage(data.emotion)}
                ></img>
                {/* <div className='profileemotion'>{data.emotion}</div> */}
                <div className="profileaction">{data.action}</div>
                <div className="profilefeelbetter" onClick={() => helpful(data)}>
                  {getHeart(data.helpful)}
                  <div className='feelbettertext'>Feel better?</div>
                </div>
                <div className="profiletime">{getTime(data.date)}</div>
                <div className="profiledate">{getDate(data.date)}</div>
              </div>
            ))}
            </div>
        </div>{" "}
        {/* <div className="titletext">Calendar</div>
        <div className="titledescription">
          This calendar shows emotions you’ve felt this month
        </div> */}
        <div style={{marginTop: '6vh'}}>
          <Cal></Cal>
        </div>
        
      </div>
    </div>
  );
};

export default ProfileScreen;
