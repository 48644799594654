import "./StudentsScreen.css";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { useState, useContext } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";
import { loadSchool, loadClasses, loadProfiles, updateProfile, insertProfiles, updateClass, insertClass, updateSchool, deleteClass } from '../../../realm/graphqlQueries'
import { UserContext } from '../../../realm/user.context'

const StudentsScreen = (props) => {
  const { realmUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const rowcount = 1000;
  let grid = [
    [
      { readOnly: true, value: "", width: "20vw" },
      { value: "Student Emails", width: "30vw", readOnly: true },
      // { value: "Class", width: "20vw", readOnly: true },
    ],
  ].concat(
    _.range(1, rowcount + 1).map((id) => [
      { readOnly: true, value: `Student ${id}` },
      { readOnly: true, value: "" },
      // { value: "" },
    ])
  );
  let teacherGrid = [
    [
      { readOnly: true, value: "", width: "20vw" },
      { value: "Teacher Emails", width: "30vw", readOnly: true },
      // { value: "Class", width: "20vw", readOnly: true },
    ],
  ].concat(
    _.range(1, rowcount + 1).map((id) => [
      { readOnly: true, value: `Teacher ${id}` },
      { readOnly: true, value: "" },
      // { value: "" },
    ])
  );
  const navigate = useNavigate();
  function nav() {
    navigate("/classscreen");
  }
  const [classes, setClasses] = React.useState([])
  const [classObject, setClassObject] = React.useState({})
  const [chosenClassIndex, setChosenClassIndex] = React.useState(-1)
  const [chosenClass, setChosenClass] = React.useState("");
  const [school, setSchool] = React.useState("");
  const [state, setState] = useState({ grid });
  const [teacherState, setTeacherState] = useState({ teacherGrid })

  const [open, setOpen] = React.useState(false);

  function getCurrentURL(url) {
    let place = url.indexOf("/")
    let urlSlice = url.slice(0, place);
    return urlSlice + "/app_setup_instructions_dataleader.pdf"
  }

  function AlertDialog() {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Students added"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Students added Successfully
            </DialogContentText>
            <CircularProgress style={{ marginLeft: "8vw", marginTop: "2vh" }} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function ManageClasses({lprops, lclasses, lclassObject, lsetClassObject, lsetClasses, lchosenClass,lsetGridEmpty}) {
    const [newclassName, setNewclassName] = React.useState("");
    const [classToDelete, setClassToDelete] = React.useState("");
    const { realmUser } = useContext(UserContext); 
    async function addNewClassToDB() {
      setLoading(true)
      if (newclassName === "") {
        alert("Class Name cannot be empty");
        setLoading(false)
        return;
      }
      let schoolResp = await loadSchool({name:school, district:lprops.user.user.district}, realmUser)
      if (schoolResp.school.classes.includes(newclassName)) {//className is unique within school
        alert("School already has a class with this name");
        setNewclassName("");
      } else {
        //Make class document
        const teachLis = lprops.user.user.user==="teacher"?[lprops.user.user.email]:[]
        let classDoc = {
          district: lprops.user.user.district,
          name: newclassName,
          school: school,
          students: [],
          teachers: teachLis //Only add the teacher to the list if the person adding is not an admin
        }
        insertClass(classDoc, realmUser)
        //Add class to school
        let nClasses = schoolResp.school.classes
        nClasses.push(newclassName)
        updateSchool(school,lprops.user.user.district, realmUser, { classes: nClasses })
        
        //Add class to teacher
        let mClasses = lclasses.slice()//Make a copy, so that stateUpdate fires in parent screen.
        mClasses.push(newclassName)
        if(lprops.user.user.user==="teacher"){//If admin adds a class, no changes to class.
          updateProfile(lprops.user.user.email,realmUser,{class:mClasses})
          let tprops = lprops.user
          tprops.user.class=mClasses
          props.setUser(tprops)
        }
        setNewclassName("");
        //Add class to classDocument
        let tmpClassObject = lclassObject
        tmpClassObject.classes.push(classDoc)
        console.log(newclassName + " Added. State: ", tmpClassObject,mClasses)
        lsetClassObject(tmpClassObject)
        lsetClasses(mClasses)
      }
      alert("New Class added successfully")
      setLoading(false)

    }
    async function removeClassFromDB() {
      setLoading(true)

      console.log(classObject)
      if (classToDelete === "Choose class" || classToDelete === "") {
        console.log("Choose a class to delete");
        return;
      }
      let remainingClassList = []
      let classDex = -1;
      for(let i=0;i<classObject.classes.length;i++){
        if(classObject.classes[i].name===classToDelete){
          classDex = i
        }else{
          remainingClassList.push(classObject.classes[i].name)
        }
      }
      if(classDex===-1){
        console.log("Class Object does not contain chosen class", classToDelete,classObject)
        return
      }
      //Delete Class Reference from School
      const schoolResp = await loadSchool({name:school, district:props.user.user.district}, realmUser)
      if(schoolResp.school.classes.includes(classToDelete)){
        let nClasses = schoolResp.school.classes
        nClasses.splice(nClasses.indexOf(classToDelete), 1)
        updateSchool(school,lprops.user.user.district, realmUser, { classes: nClasses })
        console.log("Removed class from ",school, nClasses)
      }
      
      //Remove Class from students and teacher class list
      const sProfiles = await loadProfiles(classObject.classes[classDex].students, realmUser)
      const tProfiles = await loadProfiles(classObject.classes[classDex].teachers, realmUser)
      let allProfiles = sProfiles.users.concat(tProfiles.users)
      allProfiles.forEach(user => {
        let unClasses = user.class
        if(unClasses.includes(classToDelete)){
        unClasses.splice(unClasses.indexOf(classToDelete), 1)
        updateProfile(user.email, realmUser, { "class": unClasses })
        }
      })
      if(lprops.user.user.user==="teacher"){
        let tprops = lprops.user
        tprops.user.class.splice(tprops.user.class.indexOf(classToDelete), 1)
        props.setUser(tprops)
      }
      //Remove Class Document
      await deleteClass({name:classToDelete, school:school, district:props.user.user.district}, realmUser)
      //Update Class Document
      
      let tmpClassObject = lclassObject
      tmpClassObject.classes.splice(classDex,1)
      console.log(tmpClassObject,remainingClassList)
      lsetClassObject(tmpClassObject)
      lsetClasses(remainingClassList)
      //Update Grid if class is freshly deleted and currently selected
      if(classToDelete===lchosenClass){
        lsetGridEmpty()

      }
      alert("Class deleted")
      setLoading(false)

      
    }

    return (
      <div>
        <div className="addorremoveschool">Add or Remove a Class</div>
        <div className="inputandbuttonrow">
          <input
            type="text"
            placeholder="Add a Class"
            value={newclassName}
            //TODO avoid rerendering everything on change
            onChange={(e) => setNewclassName(e.target.value)}
            className="newclasstextbox"
          ></input>
          <button className="addnewclass" onClick={() => addNewClassToDB()}>
            Add New Class
          </button>
          <select
            className="classselectdelete"
            onChange={(e) => setClassToDelete(e.target.value)}
          >
            <option value={"Choose class"}>Remove a Class</option>
            {lclasses && (
              lclasses.map((className) => (
                <option key={className} value={className}>{className}</option>
              )))}
          </select>{" "}
          <button className="addnewclass" onClick={() => removeClassFromDB()}>
            Remove a Class
          </button>
        </div>
      </div>
    )
  }
  async function getClasses() {
    setLoading(true)
    console.log("Get Classes Called")
    const school = props.user.user.school
    const user = props.user.user.user
    let tmpClasses = ""
    if (user === "admin") {
      const schoolResp = await loadSchool({name:school, district:props.user.user.district}, realmUser)
      tmpClasses = schoolResp.school.classes
      await setClasses(schoolResp.school.classes)
    }
    if (user === "teacher") {
      tmpClasses = props.user.user.class
      await setClasses(props.user.user.class)
    }
    
    const tmpClassObject = await loadClasses(school, tmpClasses, realmUser)
    console.log(tmpClasses,school, tmpClassObject)
    setClassObject(tmpClassObject)
    setSchool(school);
    setLoading(false)

  }




  async function deleteFromSheet(userType, listToDelete) {
    setLoading(true)
    //Remove class from the student document, has to be done seperately for each student
    //because bulk updates, with different update inputs are not accepted in GRAPHQL\
    const userProfiles = await loadProfiles(listToDelete, realmUser)
    const lChosenClass = chosenClass
    userProfiles.users.forEach(user => {
      if (userType === user.user) {//check this, so that if i add a teacher to the studentlist, i don't remove teaching priveledges when removing the teacher from the student list
        let nClass = user.class
        let idx = nClass.indexOf(lChosenClass)
        if (idx !== -1) {
          nClass.splice(idx, 1);
          updateProfile(user.email, realmUser, { "class": nClass })
        }
        if (idx === 0 && nClass.length==1){
          updateProfile(user.email, realmUser, { "class": [] })
        }
      }
    })
    //Remove students from the class document
    let tmpClassObject = classObject
    if (userType === "student") {
      let nUsers = tmpClassObject.classes[chosenClassIndex].students.filter(x => !listToDelete.includes(x))
      tmpClassObject.classes[chosenClassIndex].students = nUsers
      await updateClass(school, chosenClass, realmUser, { "students": nUsers })
    }
    else if (userType === "teacher") {
      let nUsers = tmpClassObject.classes[chosenClassIndex].teachers.filter(x => !listToDelete.includes(x))
      tmpClassObject.classes[chosenClassIndex].teachers = nUsers
      await updateClass(school, chosenClass, realmUser, { "teachers": nUsers })
    }
    setClassObject(tmpClassObject)
    alert("Selected students and teachers removed from class")
    setLoading(false)
  }
  async function addFromSheet(userType, emailListU) {
    setLoading(true)
    console.log("Set Variables: ", classObject.classes[chosenClassIndex])
    let emailList = emailListU.filter(x => isValidEmail(x)).map(x => x.toLowerCase())
    let allUsers = userType === "student" ? classObject.classes[chosenClassIndex].students : classObject.classes[chosenClassIndex].teachers
    console.log(userType, allUsers, emailList)
    let tmpClassObject = classObject
    console.log("made inside the addFromSheet sheet func")
    const usersRef = await loadProfiles(emailList, realmUser)
    let oldUsers = []
    usersRef.users.forEach(user => {
      allUsers.push(user.email)
      console.log(user)
      oldUsers.push(user.email)
      if (!(chosenClass in user.class)) {
        console.log("Class is being added for ", user.email)
        user.class.push(chosenClass)
        updateProfile(user.email, realmUser, { "class": user.class, "user":userType })
      }
    })
    let newUsers = emailList.filter(x => !oldUsers.includes(x));
    let userData = []
    let dataUseToAdd = (userType==="student")
    newUsers.forEach(userEmail => {
      allUsers.push(userEmail)
      userData.push({
        district: props.user.user.district,
        email: userEmail,
        school: school,
        user: userType,
        agreedToTerms: false,
        dataUse: dataUseToAdd,
        class: [chosenClass],
        count:0,
        wellnesstimer:"30 seconds"
      })
    })
    allUsers = [...new Set(allUsers)] //make sure added teachers/students are unique to the class
    //Update local class object and lists
    //allAdmins = [...new Set(tmpDistrictObject.districts[chosenDistrictIndex].superadmins.concat(addedAdmins))]
    //Update Class Document
    if (userData.length !== 0) {
      const resp = await insertProfiles(userData, realmUser)
      console.log(resp, "Successfully added profiles", newUsers)
    }
    if (userType === "student") {
      tmpClassObject.classes[chosenClassIndex].students = allUsers
      await updateClass(school, chosenClass, realmUser, {
        "students": allUsers
      })
    } else if (userType === "teacher") {
      tmpClassObject.classes[chosenClassIndex].teachers = allUsers
      await updateClass(school, chosenClass, realmUser, {
        "teachers": allUsers
      })
    }
    console.log("classObject updated", tmpClassObject)
    setClassObject(tmpClassObject)
    setChosenClassUpdateGrid(chosenClass)
    alert("Students and Teachers Added Successfully")
    setLoading(false)
  }
  function setGridEmpty(){
    let grid = [
      [
        { readOnly: true, value: "", width: "20vw" },
        { value: "Student Emails", width: "30vw", readOnly: true },
      ],
    ].concat(
      _.range(0, rowcount).map((id) => [
        { readOnly: true, value: `Student ${id + 1}` },
        { value: "" },
        // { value: "" },
      ])
    );
    setState({ grid });
    let teacherGrid = [
      [
        { readOnly: true, value: "", width: "20vw" },
        { value: "Teacher Emails", width: "30vw", readOnly: true },
      ],
    ].concat(
      _.range(0, rowcount).map((id) => [
        { readOnly: true, value: `Teacher ${id + 1}` },
        { readOnly: true, value: "" },
        // { value: "" },
      ])
    );
    setTeacherState({ teacherGrid });
  }

  function setChosenClassUpdateGrid(chosenclass) {
    //Good candidate for caching
    if (chosenclass === "Choose class") {
      setGridEmpty()
      setChosenClass(chosenclass);
    } else {
      let tmpTeachers = []
      let tmpStudents = []
      console.log(classObject)
      for (let i = 0; i < classObject.classes.length; i++) {
        console.log(classObject.classes[i])
        if (classObject.classes[i].name === chosenclass) {
          tmpTeachers = classObject.classes[i].teachers
          tmpStudents = classObject.classes[i].students
          setChosenClassIndex(i)
        }
      }
      grid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Student Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Student ${id + 1}` },
          { value: tmpStudents[id] },
          // { value: "" },
        ])
      );
      setState({ grid });
      teacherGrid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Teacher Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Teacher ${id + 1}` },
          { value: tmpTeachers[id] },
          // { value: "" },
        ])
      );
      setTeacherState({ teacherGrid });
      setChosenClass(chosenclass);
    }
  }
  React.useEffect(() => {
    getClasses();
    console.log("From get effect", classObject)
  }, []);


  return (
    <div>
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
    <div className="studentscol">
      <div className="profileoutercolumn"></div>
      {props.user.user.user === "admin" ? (
        <div>
          <div className="schoolsheader">Admin View: Set up classes in {school} </div>
          <div style={{ width: "70vw", textAlign: 'center' }}>This page is for the data entry person at your school.
            The page allows you to add or remove classes, teachers and students.
            Please be careful with this data entry page.
            For instructions on how to add/remove students/teachers/classes, <a href={getCurrentURL(window.location.href)}>please click here</a>
          </div>
        </div>
      ) : (
        <div className="schoolsheader">Teacher View: Set up classes in {school} </div>
      )}
      <div className="editclassesrow">
        <div>
          <ManageClasses 
            lrealmUser={realmUser}
            lprops={props}
            lclasses={classes}
            lclassObject={classObject} 
            lsetClassObject={setClassObject}
            lsetClasses={setClasses}
            lsetGridEmpty={setGridEmpty}
            lchosenClass={chosenClass}
          ></ManageClasses>
          <div className="selectstudentsrow">
            {chosenClass === "Choose class" || chosenClass === "" ? (
              <div className="chooseclasstext">
                Choose a class to start adding students/staff!
              </div>
            ) : (
              <div className="chooseclasstext">
                Add Teachers to  Class: {chosenClass}
              </div>
            )}
            <select
              className="schoolselect"
              value={chosenClass}
              onChange={(e) => setChosenClassUpdateGrid(e.target.value)}
            >
              <option value={"Choose class"}>Choose class</option>
              {classes &&
                classes.map((className) => (
                  <option key={className} value={className}>{className}</option>
                ))}
            </select>{" "}
          </div>
        </div>
      </div>
      <div className="spreadsheetrow">
        <div className="limitspreadsheet">
          <ReactDataSheet
            data={teacherState.teacherGrid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={(changes) => {
              let deletedTeachers = []
              let addedTeachers = []
              const teacherGrid = teacherState.teacherGrid.map((row) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                teacherGrid[row][col] = { ...teacherGrid[row][col], value };
                if (value === "") {
                  deletedTeachers.push(cell.value)
                }
                if (value) {
                  addedTeachers.push(value)
                }
              });
              if (addedTeachers.length > 0) {
                addFromSheet("teacher", addedTeachers)
              }
              if (deletedTeachers.length > 0) {
                deleteFromSheet("teacher", deletedTeachers)
              }
              setTeacherState({ teacherGrid });
            }}
          />{" "}
        </div>
      </div>
      <div className="chooseclasstext" style={{ width: '100%', marginLeft: '10vw' }}>
        Add Students {chosenClass === "Choose class" ? "" : "to " + chosenClass}
      </div>
      <div className="spreadsheetrow">
        <div className="limitspreadsheet">
          <ReactDataSheet
            data={state.grid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={(changes) => {
              console.log("these are the changes: ", changes)
              let deletedStudents = []
              let addedStudents = []
              const grid = state.grid.map((row) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
                if (value === "") {
                  deletedStudents.push(cell.value)
                }
                if (value) {
                  addedStudents.push(value)
                }
              });
              if (addedStudents.length > 0) {
                console.log("added students ", addedStudents, classObject)
                addFromSheet("student", addedStudents)
              }
              if (deletedStudents.length > 0) {
                deleteFromSheet("student", deletedStudents)
              }
              setState({ grid });
            }}
          />{" "}
        </div>
        <AlertDialog />
      </div>
      <div className="studentsbuttonrow">
        <button className="seeclassdata" onClick={() => nav()}>
          See Class Data
        </button>
      </div>
    </div>
    </div>
  );
};
export default StudentsScreen;
