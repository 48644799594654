import React, { useContext, useEffect, useState } from "react";
import { authentication, firestore } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Store, { Context } from "../../components/Store";
import "./HomeScreen.css";
import Sidebar from "../../components/Sidebar";
import moment from "moment";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Context3 } from "../../components/Timer";
import { Context2 } from "../../components/Audio";
import { loadProfile } from "../../realm/graphqlQueries";
import { UserContext } from "../../realm/user.context";
import { getCardActionsUtilityClass } from "@mui/material";

const HomeScreen = (props) => {
  const [count, setCount] = useState(0);
  const [studentName, setStudentName] = useState("");
  const navigate = useNavigate();
  //const [count, setCount] = useState(0);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const [time, setTime] = useContext(Context3);
  const [playing, setPlaying] = useContext(Context2);

  const { realmUser, logInRealmUser, logOutRealmUser, getValidAccessToken} = useContext(UserContext);

  async function getCount() { 
    const realmResp = await loadProfile(authentication.currentUser.email, realmUser);
    setCount(realmResp.user.count)
  }
  
  useEffect(() => {
    if (authentication.currentUser.displayName === null) {
      setStudentName("");
    } else {
      const fullname = authentication.currentUser.displayName;
      const myArray = fullname.split(" ");
      let name = myArray[0];
      setStudentName(name);
  
      
    }
    getCount();
  }, []);
 
  function clearDataPointAndNav() {
    setWellnessDataPoint(initialState);
    setPlaying(false);
    navigate("/deepbreathe");
  }
  const LittleCastles = () => {
    const numCastles = Math.floor(props.user.user.count / 60);
    let arr = [];
    for(let i = 0; i < numCastles; i++){
      arr.push(i)
    }
    return(
      <div class="littlecastlerow">
        {arr.map(count =>(
        <img
          class="littlecastle"
          src={"../images/Castle_Gifs/Castle(60).gif"}
          alt="loading littl..."
          loading="lazy"
        />
        ))}
      </div>
    );
  };
  
  return (
    <>
      <div class="outermostrow">
        <div class="buildcastle">
          <div class="welcome">Welcome {studentName}!</div>
          {props.user.user.count!==null && props.user.user.count % 60 === 0 ? (
            <img
              class="castle"
              src={"../images/Castle_Gifs/Castle(60).gif"}
              alt="loading larger..."
              loading="lazy"
            />
          ) : (
            <img
              class="castlebigger"
              src={"../images/Castle_Gifs/Castle(" + (props.user.user.count % 60) + ").gif"}
              alt="loading..."
              loading="lazy"
            />
          )}
          {((props.user.user.count===null) || (props.user.user.count === 0)) && (
            <h1 class="hometext">
              Time to get started on your wellness journey!
            </h1>
          )}
          {((props.user.user.count !== null) && (props.user.user.count>0)) && (
            <h1 class="hometext">
              You have practiced wellness {props.user.user.count} time(s)! Great work!
            </h1>
          )}
          <button onClick={clearDataPointAndNav} className="wellnessButtons">Keep Building</button>
          {props.user.user.count > 60 && <LittleCastles></LittleCastles>}
        </div>
      </div>
    </>
  );
};
const initialState = {
  action: "",
  emotion: "",
  date: "",
  helpful: false,
};
export default HomeScreen;
