
import "./NoAccountScreen.css";
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, OAuthProvider, signInWithRedirect} from "firebase/auth";
import { authentication } from "../../firebase";
// import GoogleButton from "react-google-button";
import GoogleButton from "../../components/GoogleButton";
import EmailLogin from "../../components/EmailLogin";
import EmailButton from "../../components/EmailButton";
import MicrosoftButton from "../../components/MicrosoftButton";
import {useEffect, useState} from 'react'


const NoAccountScreen = () => {
  var [emailClick, setEmailClicked] = useState(false);
  var [showEmailErr, setShowEmailErr] = useState(false);
  var [logErr, setLogErr] = useState(false);
  var [emailSent, setEmailSent] = useState(false);
  var [diffCredential, setDiffCredential] = useState(false);

  function signUserIn(email, password) {
    signInWithEmailAndPassword(authentication, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, ": ", errorMessage)
      if (errorCode == "auth/invalid-email") {
        setShowEmailErr(true);
      } else if (errorCode == "auth/wrong-password") {
        setLogErr(true);
      } else {
        createUser(email, password);
      }
    });
  }
  

  function createUser(email, password) {
    createUserWithEmailAndPassword(authentication, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode == "auth/invalid-email") {
        setShowEmailErr(true);
      } else {
        setLogErr(true);
      }
      // ..
    });
  }
  

  // for some reason this isn't working reliably
  function sendPasswordReset(email) {
    sendPasswordResetEmail(authentication, email)
      .then(function() {
        console.log("email sent")
        setEmailSent(true);
      })
      .catch(function() {
        console.log("failed to send email")
      })
  }

  const microProvider = new OAuthProvider('microsoft.com');
  microProvider.setCustomParameters({
    // Force re-consent.
    prompt: 'select_account',
    // Target specific email with login hint.
    // login_hint: ''
  });

  function MicrosoftLogin() {
    signInWithPopup(authentication, microProvider)
    .then((result) => {
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.

      // Get the OAuth access token and ID Token
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      console.log("this worked well")
    })
    .catch((error) => {
      console.log(error.code, " is the error")

      if (error.code == "auth/account-exists-with-different-credential") {
        console.log("this is a bust")
        setDiffCredential(true);
      }
      // Handle error.
    });
  }

  return (
      <div class="outer">
        <img
          class="logo"
          src="../images/123WellnessLogo-01.png"
          alt="123 Wellness Logo"
          loading="lazy"
        ></img>
        <div class="nosignin">Looks like either your email or password is incorrect. Try again or login with a different provider. Also note, you can't create an account on your own. Contact your teacher or an admin to create an account for you or ask for the associated email.</div>
        {!emailClick ? <div style={{display: 'flex', flexDirection: 'column'}}>
          <button onClick={Signin} style={{background: 'transparent', border: 'none', marginTop: "8vh", marginBottom: '2vh'}}><GoogleButton /></button>
          <button onClick={() => MicrosoftLogin()} style={{background: 'transparent', border: 'none', borderRadius: 'initial', marginBottom: '2vh'}}><MicrosoftButton /></button>
          <button onClick={() => setEmailClicked(true)} style={{background: 'transparent', border: 'none'}}><EmailButton /></button>
        </div> : <EmailLogin hideLogin={setEmailClicked} authUser={signUserIn} passwordReset={sendPasswordReset} emailErr={showEmailErr} handleEmailErr={setShowEmailErr} isErr={logErr} handleErr={setLogErr} emailSuccess={emailSent} handleEmailSuccess={setEmailSent}/>}
              <div style={{marginTop:'5vh'}}class='patentpending'>Patent Pending</div>
      <div class='growthwell'>© Growthwell LLC 2023</div>
      <div style={{display: 'flex', gap: '20px', }}>
          <div><a href="https://drewschwartz.com/privacy-policy" className="growthwell" target="_blank">Privacy Policy</a></div>
          <div><a href="https://drewschwartz.com/terms-of-service" className="growthwell" target="_blank">Terms of Service</a></div>
        </div>
      <img
          class="bottomdesign"
          src="../images/overlaping colors.png"
          alt="design"
          loading="lazy"
        ></img>
      </div>
  );
};


export const Signin = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    //   // Force re-consent.
      prompt: 'select_account',
    //   // Target specific email with login hint.
    //   // login_hint: ''
    });
  signInWithPopup(authentication, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // console.log("logged in" + token);
      // The signed-in user info.
      // const user = result.user
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error " + errorCode + " " + errorMessage);
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  };

export default NoAccountScreen;
