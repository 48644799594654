import { useEffect, useState, useContext } from "react";
import { authentication, Signout } from "../../firebase";
import "./OptionsScreen.css";
import { Switch, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import {UserContext} from "../../realm/user.context";
import {updateProfile } from "../../realm/graphqlQueries";


const OptionsScreen = (props) => {
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [email, setEmail] = useState("");
  const [switchVal, setSwitchVal] = useState(true);
  const [wellnessTimer, setWellnessTimer] = useState("1 minute");
  const [voice, setVoice] = useState("english female");
  const { realmUser, logOutRealmUser } = useContext(UserContext);

  const theme = createTheme({
    palette: {
      custom: {
        main: "#bd92e4",
      },
    },
  });
  useEffect(() => {
    console.log("Options Screen")
    setName(authentication.currentUser.displayName);
    setImg(authentication.currentUser.photoURL);
    setEmail(authentication.currentUser.email);
    setWellnessTimer(props.user.user.wellnesstimer);
    setSwitchVal(props.user.user.dataUse);
    if(props.user.user.voice){
      setVoice(props.user.user.voice)
    }
  }, [props.user.user.wellnesstimer,props.user.user.dataUse]);

  const navigate = useNavigate();
  function logOutAndNav() {
    Signout();
    logOutRealmUser();
    navigate("/");
  }
  async function handleSwitch() {
   await updateProfile(authentication.currentUser.email,realmUser,{dataUse:!switchVal})
   console.log("Value switched to",!switchVal)
   let tmpProps = props.user
   tmpProps.user.dataUse = !switchVal //ANTI PATTERN ALERT
   props.setUser(tmpProps)
   console.log(props.user.user, tmpProps.user)
    setSwitchVal(!switchVal);
  }
  async function handleDropDown(value) {
    console.log(value);
    await updateProfile(authentication.currentUser.email,realmUser,{wellnesstimer:value})
    setWellnessTimer(value);
    let tmpProps = props.user 
    tmpProps.user.wellnesstimer=value
    props.setUser(tmpProps)
  }

  async function handleVoiceDropdown(value) {
    console.log(value);
    const realmResp = await updateProfile(authentication.currentUser.email,realmUser,{voice:value})
    setVoice(value);
    let tmpProps = props.user 
    tmpProps.user.voice=value
    props.setUser(tmpProps)
  }
  return (
    <div class="optionsoutercolumn">
      <div class="optionsprofilerow">
        <img class="optionsimg" src={img} alt="...loading"></img>
        <div>
          <div class="fullname">{name}</div>
          <div class="optionsemail">{email}</div>
        </div>
      </div>
      <div class="dataprivacy">Wellness timer</div>
      <div class="wellnesstimertext">
        Change the amount of time that you practice wellness during step 3. 
      </div>
      <select
        class="optionselect"
        value={wellnessTimer}
        onChange={(e) => handleDropDown(e.target.value)}
      >
        <option value={"10 seconds"}>10 seconds</option>
        <option value={"20 seconds"}>20 seconds</option>
        <option value={"30 seconds"}>30 seconds</option>
        <option value={"1 minute"}>1 minute</option>
        <option value={"2 minutes"}>2 minutes</option>
      </select>{" "}
      <div class="dataprivacy">Wellness Voiceover</div>
      <div class="wellnesstimertext">
        Change the voice that guides you through the Wellness Practice. 
      </div>
      <select
        class="optionselect"
        value={voice}
        onChange={(e) => handleVoiceDropdown(e.target.value)}
      >
        <option value={"english female"}>Lina (English)</option>
        <option value={"english male"}>Drew (English)</option>
        <option value={"spanish female"}>Lina (Spanish)</option>
      </select>{" "}
      <div class="dataprivacy">Data Privacy</div>
      <div class="switchrow">
        <div class="dataprivacytext">
          Make my individual data visible to my teacher(s) and administrator(s)
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <ThemeProvider theme={theme}>
            <Switch
              value={switchVal}
              color={"custom"}
              checked={switchVal}
              onChange={() => handleSwitch()}
              className='switchstyle'
            />
          </ThemeProvider>
          {switchVal ? <div>On</div> : <div>Off</div>}
        </div>
        
      </div>
      <div class="account">Account</div>
      <div class="buttonrow">
        {/* <button class="switchuser">Switch User</button> */}
        <button class="logout" onClick={logOutAndNav} className="wellnessButtons" style={{backgroundColor: '#C2353B', color: 'white', fontWeight: 'bold'}}>
          Log Out
        </button>
      </div>
    </div>
  );
};

export default OptionsScreen;
