import ReactDataSheet from "react-datasheet";
import "./SchoolsScreen.css";
import "react-datasheet/lib/react-datasheet.css";
import { useState, useContext, useEffect } from "react";
import _ from "lodash";
import {loadDistrict, loadSchools2, updateSchool, loadProfiles, insertProfiles, updateProfile, updateProfiles, updateDistrict, insertSchool, deleteClasses, deleteSchool} from '../../../realm/graphqlQueries'
import { UserContext } from "../../../realm/user.context";
import { CircularProgress } from "@mui/material";

const SchoolsScreen = (props) => {
  const { realmUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const rowcount = 10;
  let grid = [
    [
      { readOnly: true, value: "", width: "20vw" },
      { value: "Admin Emails", width: "30vw", readOnly: true },
      // { value: "Class", width: "20vw", readOnly: true },
    ],
  ].concat(
    _.range(1, rowcount + 1).map((id) => [
      { readOnly: true, value: `Admin ${id}` },
      { readOnly: true, value: "" },
      // { value: "" },
    ])
  );
  const [chosenSchool, setChosenSchool] = useState("");
  const [chosenSchoolIndex, setChosenSchoolIndex]=useState(-1);
  const [schoolObject, setSchoolObject] = useState({});
  const [schoolList, setSchoolList] = useState([])
  const [newSchoolName, setNewSchoolName] = useState("");
  const [state, setState] = useState({ grid });
  const [district, setDistrict] = useState("");
  const [removeSchoolName, setRemoveSchoolName] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  async function addNewSchoolToDB() {
    setLoading(true)
    let districtSchools = schoolList//make copy so state change is recognized
    console.log(districtSchools, schoolObject)
    if(newSchoolName===""){
      alert("School Name cannot be empty")
      setLoading(false)
      return
    }
    if(districtSchools.includes(newSchoolName)){
      alert("District already contains a school with this name")
      setLoading(false)
      return
    }
    districtSchools.push(newSchoolName)
    await updateDistrict(district,realmUser,{"schools":districtSchools})
    const schoolData = {
      "name":newSchoolName,
      "admins":[],
      "classes":[],
      "district":district
    } 
    await insertSchool(schoolData,realmUser)
    setSchoolList(districtSchools)
    let tmpSchoolObject = schoolObject
    tmpSchoolObject.schools.push(schoolData)
    setSchoolObject(tmpSchoolObject)
    setNewSchoolName("");
    setLoading(false)

  }
  async function removeSchoolFromDB() {
    setLoading(true)
    setChosenSchoolUpdateGrid("Choose school")
    let tmpSchoolObject = schoolObject
    let schoolDex = -1
    console.log(tmpSchoolObject)
    for(let i =0;i<tmpSchoolObject.schools.length;i++){
      if(tmpSchoolObject.schools[i].name===removeSchoolName){
        schoolDex=i
      }
    }
    if(schoolDex===-1){
      alert("Chosen school not in local object")
    }
    tmpSchoolObject.schools.splice(schoolDex,1)
    let tmpSchoolList=tmpSchoolObject.schools.map(x=>x.name)
    //Remove school from district
    await updateDistrict(district,realmUser,{schools:tmpSchoolList})
    //Remove associated classes, remove school and classes from associated profiles.
    const updatedProfileData = {"school":"","class":[]}
    const removedProfiles = await updateProfiles({school:removeSchoolName,district:district},realmUser,updatedProfileData)
    const removedClasses = await deleteClasses({school:removeSchoolName,district:district},realmUser)
    const removedSchool = await deleteSchool(district,removeSchoolName,realmUser)
    console.log("successfully removed the school", removedProfiles,removedClasses,removedSchool)
    //Set State
    setSchoolObject(tmpSchoolObject)
    setSchoolList(tmpSchoolList)
    setRemoveSchoolName("Choose a school");
    setLoading(false)
  }
  
  async function addFromSheet(laddedAdmins){
    setLoading(true)
    console.log("Adding school admins ", laddedAdmins)
    //update School
    let addedAdmins = laddedAdmins.filter(x=>isValidEmail(x)).map(x=>x.toLowerCase())
    let uniqueAdmins = addedAdmins.filter(x=>!schoolObject.schools[chosenSchoolIndex].admins.includes(x))
    let allAdmins = schoolObject.schools[chosenSchoolIndex].admins.concat(uniqueAdmins)
    //add new admins
    await updateSchool(chosenSchool,district,realmUser,{admins:allAdmins})
    const profiles = await loadProfiles(uniqueAdmins,realmUser)
    console.log(profiles)
    const oldUsers = profiles.users.map(x=>x.email)
    //We need to make sure admins are correctly reassigned if theyr roles are switched
    oldUsers.forEach(userEmail => {
      let userUpdateData={
        email:userEmail,
        user:"admin",
        school:chosenSchool,
        district:district,
      }
      updateProfile(userEmail,realmUser,userUpdateData)
    })
    let newUsers = uniqueAdmins.filter(x=>!oldUsers.includes(x))
    let userData = []
    newUsers.forEach(userEmail=>{
      userData.push({
        email:userEmail,
        school: chosenSchool,
        user: "admin",
        agreedToTerms:false,
        dataUse:false,
        class:[],
        district:district,
        count:0,
        wellnesstimer: "30 seconds",
      })
    })
    if (userData.length !== 0) {
      const resp = await insertProfiles(userData, realmUser)
      console.log(resp, "Successfully added profiles", newUsers)
    }
    //update schoolObject
    let tmpSchoolObject = schoolObject
    tmpSchoolObject.schools[chosenSchoolIndex].admins = uniqueAdmins
    setSchoolObject(tmpSchoolObject)
    setLoading(false)
  }

  async function deleteFromSheet(ldeletedAdmins){
    setLoading(true)
    let deletedAdmins = ldeletedAdmins.filter(x=>isValidEmail(x)).map(x=>x.toLowerCase())
    //delete current school from admin account to remove access
    deletedAdmins.forEach(email=>{
      updateProfile(email,realmUser,{"school":""})
    })
    //update school
    let remainingAdmins = schoolObject.schools[chosenSchoolIndex].admins.filter(x=>!deletedAdmins.includes(x))
    await updateSchool(chosenSchool,district,realmUser,{"admins":remainingAdmins})
    console.log("deleting admins: ",deletedAdmins,"remaining:",remainingAdmins)
    //update state
    let tmpSchoolObject = schoolObject
    tmpSchoolObject.schools[chosenSchoolIndex].admins=remainingAdmins
    setSchoolObject(tmpSchoolObject)
    setLoading(false)

  }

  
  function updateGrid(chosenschool, chosenIndex) {
    let grid = [];
    if (chosenschool === "Choose school") {
      grid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Admin Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Admin ${id + 1}` },
          { readOnly: true, value: "" },
          // { value: "" },
        ])
      );
      setState({ grid });
    } else {
      grid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Admin Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Admin ${id + 1}` },
          { value: schoolObject.schools[chosenIndex].admins[id] },
          // { value: "" },
        ])
      );
    }
    setState({ grid });
  }

  function setChosenSchoolUpdateGrid(chosenschool) {
    let chosenDex = -1
    if(schoolObject.schools){
    for (let i =0;i<schoolObject.schools.length;i++){
      if(schoolObject.schools[i].name===chosenschool){
        chosenDex=i
      }
    }}
    if(chosenDex===-1){
      console.log("School not found in DB", chosenschool, schoolObject)
    }
    updateGrid(chosenschool, chosenDex);
    setChosenSchool(chosenschool);
    setChosenSchoolIndex(chosenDex)
  }
  async function getData() {
    setLoading(true)
    const ldistrict = props.user.user.district
    const districtResp = await(loadDistrict(ldistrict,realmUser))
    console.log(districtResp,districtResp.district.schools)
    
    const schoolsResp = await loadSchools2({district:ldistrict},realmUser)
    console.log(schoolsResp)
    setSchoolList(districtResp.district.schools)
    await setSchoolObject(schoolsResp)
    setDistrict(props.user.user.district)
    console.log(schoolObject)
    setLoading(false)
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
    <div className="studentscol">
      <div className="schoolsheader">Set Up {district}!</div>
      <div className="editclassesrow">
        <div>
          <div className="addorremoveschool">Add or remove a school</div>
          <div className="inputandbuttonrow">
            <input
              type="text"
              placeholder="Create a school"
              value={newSchoolName}
              onChange={(e) => setNewSchoolName(e.target.value)}
              className="newschooltextbox"
            ></input>
            <button className="addnewclass" onClick={() => addNewSchoolToDB()}>
              Create school
            </button>
            <select
              className="schoolselectdelete"
              onChange={(e) => setRemoveSchoolName(e.target.value)}
              value={removeSchoolName}
            >
              <option value={"Choose school"}>Remove a school</option>
              {schoolList.map((classname) => (
                <option key={classname} value={classname}>{classname}</option>
              ))}
            </select>{" "}
            <button className="addnewclass" onClick={() => removeSchoolFromDB()}>
              Remove a school
            </button>
          </div>
          <div>
            <div className="selectschoolrow">
              {chosenSchool === "Choose school" || chosenSchool === "" ? (
                <div className="chooseaschooltext">
                  Choose a school to add admins to
                </div>
              ) : (
                <div className="chooseaschooltext">
                  Adding new admin to {chosenSchool}!
                </div>
              )}
              <select
                className="schoolselect"
                onChange={(e) => setChosenSchoolUpdateGrid(e.target.value)}
              >
                <option value={"Choose school"}>Choose school</option>
                {schoolList &&
                  schoolList.map((schoolName) => (
                    <option key={schoolName} value={schoolName}>{schoolName}</option>
                  ))}
              </select>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="spreadsheetrowschools"> */}
      <div className="limitspreadsheetschools">
        <ReactDataSheet
          data={state.grid}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={(changes) => {
            let addedAdmins = []
            let deletedAdmins = []
            const grid = state.grid.map((row) => [...row]);
            changes.forEach(({ cell, row, col, value }) => {
              grid[row][col] = { ...grid[row][col], value };
                if (value === "") {
                  deletedAdmins.push(cell.value)
                }
                if (value) {
                  addedAdmins.push(value)
                }
            });
            if (addedAdmins.length > 0) {
              addFromSheet(addedAdmins)
            }
            if (deletedAdmins.length > 0) {
              deleteFromSheet(deletedAdmins)
            }
            setState({ grid });
          }}
        />{" "}
      </div>
      {/* <AlertDialog /> */}
      {/* </div> */}
    </div>
    </div>
  );
};
export default SchoolsScreen;
