import "react-datasheet/lib/react-datasheet.css";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./WellnessStudentsScreen.css";
import { updateDistrict, loadClasses2, loadDistricts, loadSchools2, updateClass, loadProfile, loadProfiles, loadSchool, updateProfile, updateSchool, deleteClass, deleteSchool, insertProfiles, insertClass, insertSchool, deleteClasses, updateProfiles  } from "../../../realm/graphqlQueries";
import { UserContext } from "../../../realm/user.context";

const WellnessStudentsScreen = () => {
  const navigate = useNavigate();
  const [districtObj, setDistrictObj] = useState({}); 
  const [chosenDistrict, setChosenDistrict] = useState("Choose District");
  const [chosenSchool, setChosenSchool] = useState("Choose School");
  const [chosenClass, setChosenClass] = useState("Choose class");

  const [newSchoolName, setNewSchoolName] = useState("");
  const [schoolToDelete, setSchoolToDelete] = useState("");
  const [newClassName, setNewClassName] = useState("");
  const [classToDelete, setClassToDelete] = useState("Choose class");
  const [newAdminName, setNewAdminName] = useState("");
  const [adminToDelete, setAdminToDelete] = useState("Remove an admin");
  const [newTeacherName, setNewTeacherName] = useState("");
  const [deleteTeacherName, setDeleteTeacherName] = useState("");
  const [loading, setLoading] = useState(true);

  //const [school, setSchool] = useState("");
  //const [user, setUser] = useState("");
  
  
  const {realmUser} = useContext(UserContext);
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  
  async function getData() {
    setLoading(true)
    let tmpDistrictObj = {}
    //Load all data in big requests.
    const districtRef = await loadDistricts(realmUser)
    const schoolRef = await loadSchools2({},realmUser)
    const classRef = await loadClasses2({},realmUser)
    console.log(classRef)
    districtRef.districts.forEach(district=>{
      //console.log(district)
      const selectedSchools = schoolRef.schools.filter(x=>x.district===district.name)
      console.log(selectedSchools)
      tmpDistrictObj[district.name]=district
      tmpDistrictObj[district.name]["schools"]={}
      selectedSchools.forEach(school=>{
        tmpDistrictObj[district.name]["schools"][school.name]=school
        const selectedClasses = classRef.classes.filter(x=>x.district===district.name&&x.school===school.name)
        const classObj = convertArrayToObject(selectedClasses,'name')
        console.log(classObj)
        tmpDistrictObj[district.name]["schools"][school.name]["classes"]=classObj
        console.log(tmpDistrictObj[district.name]["schools"][school.name])
      })
    })
    console.log(tmpDistrictObj)
    setDistrictObj(tmpDistrictObj)
    setLoading(false)
  }
  /*
  async function getClasses() {
    const teacherrequest = doc(
      firestore,
      "students/" + authentication.currentUser.email
    );
    const teacherinfo = await getDoc(teacherrequest);
    const school = teacherinfo.data().school;
    const user = teacherinfo.data().user;

    const schoolinforeq = doc(firestore, "schools/" + school);
    const schoolinfo = await getDoc(schoolinforeq);
    var classes = {};
    var teacherclasses = {};
    if (schoolinfo.data()) {
      const teachersClasses = schoolinfo.data().teachersClasses;
      const studentsClasses = schoolinfo.data().studentsClasses;
      if (user === "teacher") {
        var teacherclasses = {};
        var classes = {};
        Object.keys(teachersClasses)
          .filter((classname) =>
            // check that the teacher teaches that class
            teachersClasses[classname].includes(
              authentication.currentUser.email
            )
          )
          .map((classname) => {
            classes[classname] = studentsClasses[classname];
            teacherclasses[classname] = teachersClasses[classname];
          });
        setTeacherClasses(teacherclasses);
        setStudentClasses(classes);
      } else if (user === "admin") {
        setTeacherClasses(teachersClasses);
        setStudentClasses(studentsClasses);
      }
    }
    // console.log(classes)
    setUser(user);
    setSchool(school);
  }*/
  async function addNewClassToDB() {
    if (newClassName === "") {
      console.log("Choose a class name");
      return;
    } else if (Object.keys(districtObj[chosenDistrict]["schools"][chosenSchool]["classes"]).includes(newClassName)) {
      setNewClassName("");
      console.log("Class Already Added");
    } else {
      let tmpDistrictObj = districtObj
      const nClassData = {teachers:[],students:[], name:newClassName,school:chosenSchool,district:chosenDistrict}
      tmpDistrictObj[chosenDistrict]["schools"][chosenSchool]["classes"][newClassName] = nClassData
      const nClassList = Object.keys(districtObj[chosenDistrict]["schools"][chosenSchool]["classes"])
      await updateSchool(chosenSchool,chosenDistrict,realmUser,{"classes":nClassList})
      await insertClass(nClassData,realmUser)
      setDistrictObj(tmpDistrictObj)
      setNewClassName("");
      navigate("/wellnessstudents");
    }
  }
  async function removeClassFromDB() {
    if (classToDelete === "Choose class" || classToDelete === "") {
      console.log("Choose a class to delete");
      return;
    }
    let nSchoolClassList = Object.keys(districtObj[chosenDistrict]["schools"][chosenSchool]["classes"])
    nSchoolClassList.splice(nSchoolClassList.indexOf(classToDelete),1)
    await updateSchool(chosenSchool, chosenDistrict,realmUser,{classes:nSchoolClassList})
    let teacherList = districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][classToDelete]["teachers"]
    let studentList = districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][classToDelete]["students"]
    let allProfiles = teacherList.concat(studentList)
    const profileRef = await loadProfiles(allProfiles,realmUser)
    profileRef.users.forEach(user=>{
      if(user.class.includes(classToDelete)){
        let nUserClassList = user.class
        nUserClassList.splice(nUserClassList.indexOf(classToDelete),1)
        updateProfile(user.email,realmUser,{class:nUserClassList})
      }
    })
    await deleteClass({district:chosenDistrict,school:chosenSchool,name:classToDelete},realmUser)

    let tmpDistrictObj=districtObj
    delete(tmpDistrictObj[chosenDistrict]["schools"][chosenSchool]["classes"][classToDelete])
    setDistrictObj(tmpDistrictObj)
    navigate("/wellnessstudents");
  }
  //Attributes is an object containing at least the email and userType
  async function addUser(attributes){
    let nAttributes = attributes
    const profile = await loadProfile(attributes.email,realmUser)
    if(profile.user===null){
      console.log("inserting user ", attributes)
      insertProfiles([attributes],realmUser)
      return
    }
    else if(attributes.classes!==undefined && attributes.classes!==null){//Classes are added to a list, other attributes simply overwrite
      let nClasses = [...Set(profile.user.class.concat(attributes.classes))] 
      nAttributes.classes = nClasses     
    }
    updateProfile(attributes.email,realmUser,attributes)
  }
  
  async function addTeacher() {
    console.log("we are trying to add the teacher")
    if (chosenClass === "Choose class"){
      console.log("Choose a class")
      return;
    }
    if (newTeacherName === "" || !(isValidEmail(newTeacherName))) {
      console.log("Choose a valid teacher email");
      return;
    } 
    else if (
      districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"].includes(
        newTeacherName
      )
    ) {
      setNewTeacherName("");
      console.log("Teacher Already Added");
      return
    } else {
      const usersRef = await loadProfile(newTeacherName, realmUser)
      if(usersRef.user){
        usersRef.user.class.push(chosenClass)
        updateProfile(newTeacherName, realmUser, { "class": usersRef.user.class, "user":"teacher" })
      }
      else{
      //Add class to Teacher Document
      let userDoc = {
        email:newTeacherName,
        user:"teacher",
        class:[chosenClass],
        district:chosenDistrict,
        school:chosenSchool,
        count:0,
        dataUse:false,
        wellnesstimer:"30 seconds"
      }
      addUser(userDoc)
    }
      //add teacher to class document
      let nClassTeacherList = districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"]
      nClassTeacherList.push(newTeacherName)
      //TODO change query to include district
      updateClass(chosenSchool,chosenClass,realmUser,{"teachers":nClassTeacherList})
      //Remove teacher from local object and update State
      let tmpDistrictObject = districtObj
      tmpDistrictObject[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"] = nClassTeacherList
      setDistrictObj(tmpDistrictObject)
      setNewTeacherName("")
      navigate("/wellnessstudents");
  }
}
  async function removeTeacher() {
    if (
      deleteTeacherName === "Remove a teacher" ||
      deleteTeacherName === ""
    ) {
      console.log("Choose a teacher to remove from this class");
      return;
    }
    //Remove class from teacher profile
    const teacherProfile = await loadProfile(deleteTeacherName,realmUser)
    let nTeacherClassList = teacherProfile.user.class
    if(nTeacherClassList.includes(chosenClass)){
      nTeacherClassList.splice(nTeacherClassList.indexOf(chosenClass),1)
      console.log("New Class List",nTeacherClassList)
      updateProfile(deleteTeacherName,realmUser,{"class":nTeacherClassList})
    }
    //Remove teacher from class teacherlist
    let nClassTeacherList = districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"]
    nClassTeacherList.splice(nClassTeacherList.indexOf(deleteTeacherName),1)
    //TODO change query to include district
    updateClass(chosenSchool,chosenClass,realmUser,{"teachers":nClassTeacherList})
    //Remove teacher from local object and update State
    let tmpDistrictObject = districtObj
    tmpDistrictObject[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"] = nClassTeacherList
    setDistrictObj(tmpDistrictObject)
    setDeleteTeacherName("Remove a teacher");
    navigate("/wellnessstudents");
  }
  useEffect(() => {
    //getClasses();
    getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function updateLocalDistrict(district) {
    setChosenClass("Choose class");
    setChosenSchool("Choose School");
    setChosenDistrict(district);
    setAdminToDelete("Remove an admin")
    // setChosenClassUpdateGrid("Choose class");
  }
  function updateLocalSchool(school) {
    //console.log("changing school to", school, districtObj[chosenDistrict]["schools"][school]["admins"])
    setChosenSchool(school);
    setChosenClass("Choose class");
    setAdminToDelete("Remove an admin")
    // setChosenClassUpdateGrid("Choose class");
  }
  async function addNewSchoolToDB() {
    let schoolList = Object.keys(districtObj[chosenDistrict]["schools"])
    if(schoolList.includes(newSchoolName)){
      console.log('School already exists')
      return
    }
    schoolList.push(newSchoolName)
    //updateSchoolList
    await updateDistrict(chosenDistrict,realmUser,{schools:schoolList})
    const schoolData = {
      name:newSchoolName,
      district:chosenDistrict,
      classes:[],
      admins:[]
    }
    await insertSchool(schoolData,realmUser)
    let tmpDistrictObj = districtObj
    tmpDistrictObj[chosenDistrict]["schools"][newSchoolName]=schoolData
    setDistrictObj(tmpDistrictObj);
    setNewSchoolName("");
  }
  async function removeSchoolFromDB() {
    //remove all associated classes
    deleteClasses({district:chosenDistrict,school:schoolToDelete},realmUser)
    //remove schools and classes from associated profiles
    updateProfiles({district:chosenDistrict,school:schoolToDelete},realmUser,{class:[],school:""})
    //delete school
    deleteSchool(chosenDistrict,chosenSchool,realmUser)
    //remove school from state
    let tmpDistrictObj  = districtObj
    delete(tmpDistrictObj[chosenDistrict]["schools"][schoolToDelete])
    setSchoolToDelete("")
    setDistrictObj(tmpDistrictObj);
  }
  async function addAdminToDB() {
    if(!isValidEmail(newAdminName)){
      console.log("invalid email")
    }
    else{
    //Update or Add User Document
    let userDoc = {
      school:chosenSchool,
      district:chosenDistrict,
      user:"admin",
      email:newAdminName,
      class:[],
      count:0,
      wellnesstimer:"30 seconds",
      dataUse:false,
    }
    addUser(userDoc)
    //Update school document
    let adminList = districtObj[chosenDistrict]["schools"][chosenSchool]['admins']
    if(adminList.includes(newAdminName)){
      console.log("school already has this admin")
    }
    else{
      adminList.push(newAdminName)
      const schoolRef = await updateSchool(chosenSchool,chosenDistrict,realmUser,{admins:adminList})
      console.log(schoolRef)
      let tmpDistrictObj = districtObj
      tmpDistrictObj[chosenDistrict]["schools"][chosenSchool]['admins']=adminList
      setDistrictObj(tmpDistrictObj)
    }
  }
  setNewAdminName("")
  }
  async function removeAdminFromDB() {
    //update admin profile
    await updateProfile(adminToDelete,realmUser,{school:""})
    //update School
    const schoolRef = await loadSchool({district:chosenDistrict,name:chosenSchool},realmUser)
    let nAdmins = schoolRef.school.admins
    nAdmins.splice(nAdmins.indexOf(adminToDelete),1)
    updateSchool(chosenSchool,chosenDistrict,realmUser,{admins:nAdmins})
    let tmpDistrictObj = districtObj
    tmpDistrictObj[chosenDistrict]["schools"][chosenSchool]['admins']=nAdmins
    setDistrictObj(tmpDistrictObj)
    setAdminToDelete("Remove an admin");
    setNewAdminName("");
  }
  
  return (
    <div>
    {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
    <div class="studentscol">
      
      <div class="schoolsheader">123Wellness View: Fix Classes </div>

      <div class="editclassesrow">
        <div>
          <div class="inputandbuttonrow">
            {chosenDistrict === "Choose District" ? (
              <div class="choosedistrictandschool">Choose a district</div>
            ) : (
              <div class="choosedistrictandschool">
                Add or remove schools in {chosenDistrict}
              </div>
            )}
            <select
              class="biggerselects"
              onChange={(e) => updateLocalDistrict(e.target.value)}
            >
              <option value={"Choose District"}>Choose District</option>
              {districtObj &&
                Object.keys(districtObj).map((district) => (
                  <option value={district}>{district}</option>
                ))}
            </select>{" "}
          </div>
          <div class="inputandbuttonrow">
            <input
              type="text"
              placeholder="Add a school"
              value={newSchoolName}
              onChange={(e) => setNewSchoolName(e.target.value)}
              class="newclasstextbox"
            ></input>
            <button class="addnewclass" onClick={() => addNewSchoolToDB()}>
              Add new school
            </button>
            <select
              class="classselectdelete"
              onChange={(e) => setSchoolToDelete(e.target.value)}
            >
              <option value={"Remove a school"}>Remove a school</option>
              {chosenDistrict !== "Choose District" &&
                districtObj[chosenDistrict] &&
                Object.keys(districtObj[chosenDistrict]["schools"]).map((school) => (
                  <option value={school}>{school}</option>
                ))}
            </select>{" "}
            <button class="addnewclass" onClick={() => {
              setChosenClass("Choose class")
              setChosenSchool("Choose School")
              removeSchoolFromDB()}}>
              Remove a school
            </button>
          </div>
          <div class="selectstudentsrow">
          <div class="inputandbuttonrow">
            {chosenSchool === "Choose School" ? (
              <div class="addorremoveschool">Choose a school</div>
            ) : (
              <div class="addorremoveschool">
                Add or remove classes in {chosenSchool}
              </div>
            )}
            <select
              class="biggerselects"
              onChange={(e) => updateLocalSchool(e.target.value)}
            >
              <option value={"Choose School"}>Choose School</option>
              {chosenDistrict !== "Choose District" &&
                districtObj[chosenDistrict] &&
                Object.keys(districtObj[chosenDistrict]["schools"]).map((school) => (
                  <option value={school}>{school}</option>
                ))}
            </select>{" "}
          </div>
          </div>
          <div class="inputandbuttonrow">
            <input
              type="text"
              placeholder="Add a class"
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
              class="newclasstextbox"
            ></input>
            <button class="addnewclass" onClick={() => addNewClassToDB()}>
              Add new class
            </button>
            <select
              class="classselectdelete"
              onChange={(e) => setClassToDelete(e.target.value)}
            >
              <option value={"Choose class"}>Remove a class</option>
              {chosenDistrict !== "Choose District" &&
                chosenSchool !== "Choose School" &&
                districtObj[chosenDistrict]["schools"][chosenSchool] &&
                Object.keys(districtObj[chosenDistrict]["schools"][chosenSchool]["classes"]).map(
                  classname => <option value={classname}>{classname}</option>
                )}
            </select>{" "}
            <button class="addnewclass" onClick={() => {
              setChosenClass("Choose class")
              removeClassFromDB()}}>
              Remove a class
            </button>
          </div>
          <div class="inputandbuttonrow">
            <input
              type="text"
              placeholder={"Add new admin"}
              value={newAdminName}
              onChange={(e) => setNewAdminName(e.target.value.toLowerCase())}
              class="newschooltextbox"
            ></input>
            <button class="addnewclass" onClick={() => addAdminToDB()}>
              Add new admin
            </button>
            <select
              class="schoolselectdelete"
              onChange={(e) => setAdminToDelete(e.target.value)}
            >
              <option value={"Remove an admin"}>Remove an admin</option>
              {chosenSchool !== "Choose School" &&
                chosenSchool !== "" &&
                districtObj[chosenDistrict]["schools"][chosenSchool] &&
                districtObj[chosenDistrict]["schools"][chosenSchool]['admins'] !== undefined &&
                districtObj[chosenDistrict]["schools"][chosenSchool]['admins'].map(admin => (
                  <option value={admin}>{admin}</option>
                ))}
            </select>{" "}
            <button class="addnewclass" onClick={() => removeAdminFromDB()}>
              Remove an admin
            </button>
          </div>
          <div class="selectstudentsrow">
            {chosenClass === "Choose class" || chosenClass === "" ? (
              <div class="chooseclasstext">
                Choose a class to add/remove teachers
              </div>
            ) : (
              <div class="chooseclasstext">
                Adding/Removing teachers to {chosenClass}!
              </div>
            )}
            <select
              class="schoolselect"
              value={chosenClass}
              onChange={(e) => setChosenClass(e.target.value)}
            //   onChange={(e) => setChosenClassUpdateGrid(e.target.value)}
            >
              <option value={"Choose class"}>Choose class</option>
              {chosenDistrict !== "Choose District" &&
                chosenSchool !== "Choose School" &&
                districtObj[chosenDistrict]["schools"][chosenSchool] &&
                Object.keys(districtObj[chosenDistrict]["schools"][chosenSchool]["classes"]).map(
                  (classname) => <option value={classname}>{classname}</option>
                )}
            </select>{" "}
          </div>
          <div class="inputandbuttonrow">
            <input
              type="text"
              placeholder={"Add a teacher to " + chosenClass}
              value={newTeacherName}
              onChange={(e) => setNewTeacherName(e.target.value.toLowerCase())}
              class="newclasstextbox"
            ></input>
            <button class="addnewclass" onClick={() => addTeacher()}>
              Add new teacher
            </button>
            <select
              class="classselectdelete"
              onChange={(e) => setDeleteTeacherName(e.target.value)}
            >
              <option value={"Remove a teacher"}>
                Remove a teacher
              </option>
              {chosenClass !== "Choose class" &&
                chosenClass !== "" &&
                chosenDistrict !== "Choose District" &&
                chosenSchool !== "Choose School" &&
                districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"] &&
                  districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"] !==
                  undefined &&
                  districtObj[chosenDistrict]["schools"][chosenSchool]["classes"][chosenClass]["teachers"].map(
                  (teacher) => <option value={teacher}>{teacher}</option>
                )}
            </select>{" "}
            <button class="addnewclass" onClick={() => removeTeacher()}>
              Remove a teacher
            </button>
          </div>
        </div>
      </div>
      {/* <div class="spreadsheetrow">
        <div class="limitspreadsheet">
          <ReactDataSheet
            data={state.grid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={(changes) => {
              const grid = state.grid.map((row) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
              });
              setState({ grid });
            }}
          />{" "}
        </div>
        <AlertDialog />
      </div>
      <div class="inputandbuttonrow">
        <select
          class="removeastudentselect"
          onChange={(e) => setDeleteStudent(e.target.value)}
        >
          <option value={"Remove a student"}>
            Remove student from {chosenClass}
          </option>
          {chosenClass !== "Choose class" &&
                chosenClass !== "" &&
                chosenDistrict !== "Choose District" &&
                chosenSchool !== "Choose School" &&
                data[chosenDistrict][chosenSchool][chosenClass]['students'] !== [] &&
                data[chosenDistrict][chosenSchool][chosenClass]['students'].map((students) => (
                  <option value={students}>{students}</option>
                ))}
        </select>{" "}
        <button
          class="removeastudentbutton"
          onClick={() => removeStudentFromDB()}
        >
          Remove a student
        </button>
      </div> */}
    </div>
    </div>
  );
};
export default WellnessStudentsScreen;
