import "./Congratulations.css";
import Sidebar from "../../components/Sidebar";
import { motion } from "framer-motion";
import { Context } from "../../components/Store";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadProfile, updateStudentCount, updateWellnessHelpfulMutation, updateProfile } from "../../realm/graphqlQueries";
import { UserContext } from "../../realm/user.context";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";

const Congratulations = (props) => {
  const { realmUser } = useContext(UserContext);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  const [playing, setPlaying]= useContext(Context2);


  useEffect(() => {
    // fetchCount();
  }, []);
  // change to actually fetch the count
  async function fetchCount() {
    let nCount = 0
    if(props.user.user.count!==undefined && props.user.user.count!==null){
      nCount = props.user.user.count+1
    }
    updateProfile(props.user.user.email,realmUser,{count:nCount})
    let tmpProps = props.user
    tmpProps.user.count=nCount
    props.setUser(tmpProps)
    setCount(nCount)
    }

  async function updateDatapoint(helpful) {
    try {
      // Call the GraphQL mutation to update the 'helpful' field
      const resp = await updateWellnessHelpfulMutation(props.lWellnessId, helpful, realmUser);
      if (resp && resp.updateOneWellness && resp.updateOneWellness._id) {
        console.log("Success!");
        navigate("/profilescreen");
      } else {
        console.error("Update failed!");
        navigate("/profilescreen");
      }
    } catch (error) {
      console.error("Failed!", error);
      navigate("/profilescreen");
    }
  }

  return (
    <motion.div
      class="outercongrats"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {/* <Sidebar /> */}
      <div class='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/congratulations.wav"} />
      </div>
      <div class="columncongrats">
        {((props.user.user.count + 1) > 60) ?(<img
          class="congratulationscastle"
          src={"../images/Castle_Gifs/Castle(60).gif"}
          alt="loading larger..."
          loading="lazy"
        />):(<img
          class="congratulationscastle"
          src={"../images/Castle_Gifs/Castle(" + (props.user.user.count + 1) + ").gif"}
          alt="loading..."
          loading="lazy"
        />)}
        <div class="wasthishelpful">Was the wellness practice:</div>
        <div class="wasthishelpful">"{wellnessDataPoint.action}" helpful?</div>
        <div class="rowcongrats">
          <button class="helpfulyes" onClick={() => {updateDatapoint(true); fetchCount()}}>
            Yes
          </button>
          <button class="helpfulno" onClick={() => {updateDatapoint(false); fetchCount()}}>
            No
          </button>

          {/* <FaHeart style={{ fontSize: "1.3vw", marginTop:'1vh', marginLeft:'2vh' }}></FaHeart> */}
        </div>
      </div>
    </motion.div>
  );
};

export default Congratulations;
