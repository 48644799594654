import "./Actions.css";
import "./PerformAction.css";
import BlueSidebar from "../../components/BlueSidebar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { useContext, useEffect, useState } from "react";
import {Box, LinearProgress } from "@mui/material";
import { Context3 } from "../../components/Timer";

const PerformAction = (props) => {
  const navigate = useNavigate();
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const [string, setString] = useState("");
  const [progress, setProgress] = useState(2);
  const time = props.user.user.wellnesstimer;

  useEffect(() => {
    var timerlength = 0;
    if (time === "1 minute") {
      timerlength = 60000;
    } else if (time === "2 minutes") {
      timerlength = 120000;
    } else if (time === "30 seconds") {
      timerlength = 30000;
    }else if (time === "20 seconds") {
      timerlength = 20000;
    }else if (time === "10 seconds") {
      timerlength = 10000;
    }
    const progresstimer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, timerlength/100);
    const timer = setTimeout(() => {
      navigate("/congratulations");
    }, timerlength);
    const action = wellnessDataPoint.action;
    const otherCheck = action.split(" ");
    var temp;
    if (otherCheck[0] === "Other:") {
      temp = "../images/actions/Other.png";
    } else temp = "../images/actions/" + wellnessDataPoint.action + ".png";
    setString(temp);
    return () => {
      clearTimeout(timer);
      clearInterval(progresstimer);
    };
  }, []);

  return (
    <motion.div
      class="outerperformaction"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      <div class="classandactions">
        <div class="practicewellnesstext">
          Please take {time} to practice wellness{" "}
        </div>
        {wellnessDataPoint.action.substring(0, 6) === "Other:" && (
          <div class="altwellness">
            You chose your own wellness practice:{" "}
            {wellnessDataPoint.action.substring(6)}
          </div>
        )}
        <img class="chosenaction" src={string} alt="loading..." />
        <Box className='linearprogresspractice' >
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </div>
    </motion.div>
  );
};

export default PerformAction;
