import "./App.css";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import SigninScreen from "./screens/SigninScreen/SigninScreen";
import React, { useContext, useState, useEffect } from "react";
import { authentication, firestore } from "./firebase";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import DisclaimerScreen from "./screens/DisclaimerScreen/DisclaimerScreen";
import ReactPlayer from "react-player";
import { Context } from "./components/Store";
import NoAccountScreen from "./screens/NoAccountScreen/NoAccountScreen";
import {Signout} from "./firebase"
import {UserContext} from "./realm/user.context";
import {loadProfile} from "./realm/graphqlQueries";


// import 'firebaseui/dist/firebaseui.css'

function App() {
  const [isUserSignedIn, setIsUserSignedIn] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [userType, setUserType] = useState("");
  const [userObject, setUserObject] = useState({});
  // We are consuming our user-management context to 
  // get & set the user details here
  const { realmUser, logInRealmUser, logOutRealmUser, getValidAccessToken} = useContext(UserContext);
  // const location = useLocation();
  // three states loggedin, loggedout, noaccount
  useEffect(()=>{
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      //console.log("!! auth state change !!")
      if (user) {
        createUserData(user).then((signedin) => {
          //console.log("this is signed in: ", signedin)
          if(signedin === "noaccount")setAgreedToTerms(false);
          else if(signedin === "loggedin")setAgreedToTerms(true);
          else if(signedin === "needstoagreetoterms")setAgreedToTerms(false);
          setIsUserSignedIn(signedin);
        });
      } else {
        setIsUserSignedIn("loggedout");
        // go back to home page
      }
    });
    return unsubscribe
  }, []);
  

  //This seems to duplicate the above code lines 33-35
  /*
  if (authentication.currentUser) {
    const fbrequest = "students/" + authentication.currentUser.email;
    const terms = onSnapshot(doc(firestore, fbrequest), (doc) => {
      setAgreedToTerms(doc.data().agreedToTerms);
    });
  }*/
   // returns terms and logged in state
  async function createUserData(user) {
    //TODO why is this being called so often?
    const idToken = await user.getIdToken(/* forceRefresh */ true)
    const authToken = await getValidAccessToken()
    let realmResp = null
    if(realmUser===null){
      const authedUser = await logInRealmUser(idToken)
      realmResp = await loadProfile(authentication.currentUser.email,authedUser)
    }else{
      realmResp = await loadProfile(authentication.currentUser.email,realmUser)
    }
    if(realmResp!=null){
      //console.log(realmResp)
      if(realmResp==="InvalidSession"){
        console.log("Refresh Required, logging out user")
        Signout()
        logOutRealmUser()
        return "null"
      }
      if(realmResp.user===null){
        console.log("1")
        return "noaccount";
      }
      else{
        setUserType(realmResp.user.user)
        setUserObject(realmResp)
        if(realmResp.user.agreedToTerms===true){
          console.log("2")
          return "loggedin";
        }
        else{
          console.log("3")
          return "needstoagreetoterms"

        }
      }

    }else{
      alert("No Corresponding Realm account found, please log out and try logging in again")
    }
    /*
    const fbrequest = "students/" + authentication.currentUser.email;
    const ref = doc(firestore, fbrequest);
    const q = await getDoc(ref);
    if (!q.exists()) {
      console.log("1")
    } else if (q.data().agreedToTerms === true) {
      console.log("2")
      setUserType(q.data().user)
      return "loggedin";
      // true regular flow
    } else {
      console.log("3")
      return "needstoagreetoterms";
      // false agreetoterms
    }
    */
  }

  if (isUserSignedIn === "loggedin") {

      return (
        <Router>
          <AnimatedRoutes user={userObject} setUser={setUserObject} ></AnimatedRoutes>
        </Router>
      );
    // }
  } else if (isUserSignedIn === "loggedout") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<SigninScreen />} />
          </Routes>
        </div>
      </Router>
    );
  } else if (isUserSignedIn === "noaccount") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<NoAccountScreen />} />
          </Routes>
        </div>
      </Router>
    );
  }else if (isUserSignedIn === "needstoagreetoterms") {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<DisclaimerScreen user={userObject} />} />
          </Routes>
        </div>
      </Router>
    );
  }
}
export default App;
