import { GRAPHQL_ENDPOINT } from "./constants";
import request, { gql } from "graphql-request";

//USER SECTION

export const loadProfile = async (email, realmUser) => {
  const getProfileQuery = gql`
    query($email:String) {
      user(query: {email: $email}) {
        _id
        agreedToTerms
        class
        count
        dataUse
        district
        email
        school
        user
        wellnesstimer
        voice
      }
    }
    `;
  const queryVariables = { "email": email }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getProfileQuery, queryVariables, headers);
    return resp
  } catch (error) {
    if (error.response.error_code && error.response.error_code === "InvalidSession") {
      return "InvalidSession"
    }
    console.log(error);
    return error
  }
};


export const loadProfiles = async (emails, realmUser) => {
  const getProfilesQuery = gql`
    query($emails:[String]){
      users(limit:100000,query:{email_in:$emails}) {
        email
        _id
        user
        class
        dataUse
      }
    }
    `;
  const queryVariables = { "emails": emails }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getProfilesQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const loadProfiles2 = async (queryInput, realmUser) => {
  const getProfiles2Query = gql`
    query($queryInput: UserQueryInput) {
      users(limit:100000,query: $queryInput) {
        email
        _id
        user
        class
        school
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getProfiles2Query, { queryInput }, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};



export const insertProfiles = async (userData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertProfilesQuery = gql`
    mutation($data:[UserInsertInput!]!) {
      insertManyUsers(data: $data){
        insertedIds
      }
    }
    `;
  const queryVariables = { "data": userData }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, insertProfilesQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const updateProfile = async (email, realmUser, setObject) => {
  const updateProfileQuery = gql`
    mutation EditProfile($query: UserQueryInput!, $set: UserUpdateInput!) {
      updateOneUser(query: $query, set: $set) {
        agreedToTerms
        class
        count
        dataUse
        district
        email
        school
        user
      }
    }
    `;
  // Query variables that will be used to perform the analytics from a particular 
  // date to a particular date.
  const queryVariables = {
    "query": {
      "email": email
    },
    "set": setObject
  }//authentication.currentUser.email};

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //TODO: Handle Session Token expired. - Sign out on session token expired.
  console.log("Update Called")
  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateProfileQuery, queryVariables, headers);
    console.log(resp);
    return resp
  } catch (error) {
    console.log(error);
    if (error.response.error_code === "InvalidSession") {
      return "InvalidSession"
    }
    return error
  }
};


export const updateProfiles = async (query, realmUser, setObject) => {
  const updateProfilesQuery = gql`
    mutation EditProfiles($query: UserQueryInput!, $set: UserUpdateInput!) {
      updateManyUsers(query: $query, set: $set) {
        matchedCount
        modifiedCount
      }
    }
    `;
  // Query variables that will be used to perform the analytics from a particular 
  // date to a particular date.
  const queryVariables = {
    "query": query,
    "set": setObject
  }//authentication.currentUser.email};

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //TODO: Handle Session Token expired. - Sign out on session token expired.
  console.log("Update Called")
  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateProfilesQuery, queryVariables, headers);
    console.log(resp);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

// WILLY
// A helper function to execute GraphQL queries
export const fetchUserSchool = async (email, realmUser) => {
  const getUserSchoolQuery = gql`
    query GetUserSchool($data: UserQueryInput!) {
      user(query: $data) {
        school
      }
    }
  `;

  const queryVariables = {
    "data": {
      "email": email
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getUserSchoolQuery, queryVariables, headers);
    return resp.user.school; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchClassesBySchoolName = async (schoolName, realmUser) => {
  const getClassesQuery = gql`
    query GetClassesBySchoolName($data: SchoolQueryInput!) {
      school(query: $data) {
        classes
      }
    }
  `;

  const queryVariables = {
    "data": {
      "name": schoolName
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getClassesQuery, queryVariables, headers);
    return resp.school.classes; // Or just return resp if you want the entire response object
  } catch (error) {
    console.log(error);
    return error;
  }
};

export async function fetchStudentsData(userID, realmUser) {
  const studentDataQuery = `
    query FetchWellnessDataByUserID($userID: String!) {
      wellnesses(query: { userID: $userID }) {
        date
        emotion
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  
  try {
    const response = await request(GRAPHQL_ENDPOINT, studentDataQuery, { userID }, headers);
    return response ? response.wellnesses : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}


export async function fetchStudentEmailToIDMapping(realmUser) {
  const studentQuery = gql`
      query getAllStudents {
          users(query: { user: "student" }, limit: 1000) {
              _id
              email
          }
      }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  
  try {
      const response = await request(GRAPHQL_ENDPOINT, studentQuery, {}, headers);
      const students = response.users;
      let emailToIDMapping = {};
      
      students.forEach(student => {
          emailToIDMapping[student.email] = student._id;
      });

      return emailToIDMapping;

  } catch (error) {
      console.error("Error fetching student email-ID mapping:", error);
      throw error;
  }
}



export async function fetchStudentIDfromEmail(email, realmUser) {
  const studentDataQuery = `
  query GetUserIDByEmail($email: String!) {
      user(query: { email: $email }) {
          _id
      }
  }
`;
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const response = await request(GRAPHQL_ENDPOINT, studentDataQuery, { email }, headers);
    return response ? response : [];
  } catch (error) {
    console.log(error);
    return '';
  }
}

export async function fetchStudentsFromClass(className, realmUser) {
  const classQuery = gql`
    query($className: String!) {
      class(query: { name: $className }) {
        students
      }
    }
  `;

  const queryVariables = { className: className };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const response = await request(GRAPHQL_ENDPOINT, classQuery, queryVariables, headers);
    return response.class ? response.class.students : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const loadStudentsFromSchool = async (schoolName, realmUser) => {
  // GraphQL query to fetch all students from a specific school.
  const getStudentsFromSchoolQuery = gql`
    query($school: String) {
      users(query: { school: $school, user: "student" }) {
        email
      }
    }
  `;

  const queryVariables = { "school": schoolName };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getStudentsFromSchoolQuery, queryVariables, headers);
    return resp.users;  // Adjust this if the GraphQL response structure is different
  } catch (error) {
    console.log(error);
    return error;
  }
};


//SCHOOL SECTION

export const loadSchools = async (districtName, schoolNames, realmUser) => {
  const getSchoolsQuery = gql`
  query($schoolNames:[String],$district:String) {
    schools(query:{name_in: $schoolNames,district:$district}) {
      _id
      name
      district
      admins
    }
  }
  `;
  const queryVariables = { "classNames": schoolNames, "school": districtName }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolsQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};
export const loadSchools2 = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getSchoolQuery = gql`
  query($data:SchoolQueryInput) {
    schools(query: $data) {
      _id
      admins
      classes
      name
      district
    }
  }
  `;
  const queryVariables = { "data": data }
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const loadSchool = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getSchoolQuery = gql`
  query($data:SchoolQueryInput!) {
    school(query: $data) {
      _id
      admins
      classes
      name
    }
  }
  `;
  const queryVariables = { "data": data }
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const fetchSchoolIDsByNames = async (schoolNames, realmUser) => {
  const getSchoolIDsQuery = gql`
  query($data: SchoolQueryInput!) {
    schools(query: $data) {
      _id
    }
  }
  `;
  const queryVariables = { 
    "data": {
      name_in: schoolNames
    }
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolIDsQuery, queryVariables, headers);
    return resp.schools.map(school => school._id);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolsInfoByID = async (schoolIDs, realmUser) => {
  const getSchoolsInfoQuery = gql`
  query($data: SchoolQueryInput!) {
    schools(query: $data) {
      _id
      name
      district
      classes
    }
  }
  `;
  const queryVariables = {
    "data": {
      _id_in: schoolIDs
    }
  };
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolsInfoQuery, queryVariables, headers);
    return resp.schools;
  } catch (error) {
    console.log(error);
    return error;
  }
};



export const updateSchool = async (schoolName, districtName, realmUser, setObject) => {
  const updateSchoolQuery = gql`
  mutation EditSchool($query: SchoolQueryInput!, $set: SchoolUpdateInput!) {
    updateOneSchool(query: $query, set: $set) {
      name
    }
  }
  `;
  const queryVariables = {
    "query": {
      "name": schoolName,
      "district":districtName
    },
    "set": setObject
  }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  console.log("Update Called")
  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateSchoolQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};



export const insertSchool = async (schoolData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertSchoolQuery = gql`
    mutation($data:SchoolInsertInput!) {
      insertOneSchool(data: $data){
        name
      }
    }
    `;
  const queryVariables = { "data": schoolData }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, insertSchoolQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const deleteSchool = async (districtName, schoolName, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteSchoolQuery = gql`
    mutation($data:SchoolQueryInput!) {
      deleteOneSchool(query:$data){
        name
      }  
    }
    `;
  const queryVariables = { "data": { "district": districtName, "name": schoolName } }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, deleteSchoolQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const deleteSchools = async (query, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteSchoolsQuery = gql`
    mutation($data:SchoolQueryInput!) {
      deleteManySchools(query:$data){
        deletedCount
    }  }
    `;
  const queryVariables = { "data": query }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, deleteSchoolsQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const fetchSchoolByID = async (id, realmUser) => {
  const getSchoolQuery = gql`
    query GetSchool($data: SchoolQueryInput!) {
      school(query: $data) {
        _id
        name
        classes
      }
    }
  `;

  const queryVariables = {
    "data": {
      "_id": id
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolQuery, queryVariables, headers);
    return resp.school;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchSchoolIDByName = async (schoolName, realmUser) => {
  const getSchoolIDQuery = gql`
    query GetSchoolIDByName($data: SchoolQueryInput!) {
      schools(query: $data) {
        _id
      }
    }
  `;

  const queryVariables = {
    "data": {
      "name": schoolName  // Adjust if the field for school name in your schema is different
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getSchoolIDQuery, queryVariables, headers);
    if (resp.schools && resp.schools.length > 0) {
      return resp.schools[0]._id;
    }
    throw new Error("School not found");
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const fetchStudentByEmail = async (email, realmUser) => {
  const getStudentQuery = gql`
    query GetStudent($data: UserQueryInput!) {
      user(query: $data) {
        _id
        user
        email
        school
        dataUse
      }
    }
  `;

  const queryVariables = {
    "data": {
      "email": email
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getStudentQuery, queryVariables, headers);
    if (resp.user && resp.user.user === "student") {
      return resp.user;
    } else {
      throw new Error("Fetched user is not a student.");
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const fetchWellnessHistoryByEmail = async (email, realmUser) => {
  const getWellnessHistoryQuery = gql`
    query GetWellnessHistory($data: WellnessQueryInput!) {
      wellnesses(query: $data) {
        date
        emotion
      }
    }
  `;

  const queryVariables = {
    "data": {
      "email": email  // Assuming you have an 'email' field in your wellness type.
    }
  };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getWellnessHistoryQuery, queryVariables, headers);
    return resp.wellnesses;
  } catch (error) {
    console.log(error);
    return error;
  }
};


//CLASSES SECTION
export const loadClasses = async (schoolName, classNames, realmUser) => {
  const getClassesQuery = gql`
  query($classNames:[String],$school:String) {
    classes(query:{name_in: $classNames,school:$school}) {
      _id
      name
      school
      students
      teachers
      district
    }
  }
  `;
  const queryVariables = { "classNames": classNames, "school": schoolName }
  //console.log(getClassesQuery,queryVariables)
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getClassesQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const loadClasses2 = async (data, realmUser) => {
  const getClassesQuery = gql`
  query($data:ClassQueryInput!) {
    classes(query:$data) {
      _id
      name
      school
      students
      teachers
      district
    }
  }
  `;
  const queryVariables = { "data": data}
  console.log(getClassesQuery,queryVariables)
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getClassesQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};



export const updateClass = async (school, classname, realmUser, setObject) => {
  const updateClassQuery = gql`
    mutation EditClass($query: ClassQueryInput!, $set: ClassUpdateInput!) {
      updateOneClass(query: $query, set: $set) {
        name
        school
        students
        teachers
      }
    }
    `;
  const queryVariables = {
    "query": {
      "name": classname,
      "school": school
    },
    "set": setObject
  }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  console.log("Update Called")
  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateClassQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const insertClass = async (classData, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const insertClassQuery = gql`
    mutation($data:ClassInsertInput!) {
      insertOneClass(data: $data){
        name
      }
    }
    `;
  const queryVariables = { "data": classData }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, insertClassQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};
//TODO ADD district
export const deleteClass = async (data, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteClassQuery = gql`
    mutation($data:ClassQueryInput!) {
      deleteOneClass(query:$data){
        name
    
    }  }
    `;
  const queryVariables = { "data": data }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, deleteClassQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const deleteClasses = async (query, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const deleteClassesQuery = gql`
    mutation($data:ClassQueryInput!) {
      deleteManyClasses(query:$data){
        deletedCount
    }  }
    `;
  const queryVariables = { "data": query }

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  //Handle Session Token expired. - Sign out on session token expired.
  try {
    const resp = await request(GRAPHQL_ENDPOINT, deleteClassesQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

  //DISTRICT SECTION
export const updateDistrict = async (districtName, realmUser,setObject) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const updateDistrictQuery = gql`
  mutation EditDistrict($query: DistrictQueryInput!, $set: DistrictUpdateInput!) {
    updateOneDistrict(query: $query, set: $set) {
      name
      schools
      superadmins
    }
  }
  `;
const queryVariables = {
  "query": {
    "name": districtName,
  },
  "set": setObject
}
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateDistrictQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const loadDistrict = async (districtName, realmUser) => {
  // GraphQL query to fetch mode analytics as well as the category analytics.
  const getDistrictQuery = gql`
  query($name:String) {
    district(query: {name: $name}) {
      name
      schools
      superadmins
    }
  }
  `;
  const queryVariables = { "name": districtName }
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  try {
    const resp = await request(GRAPHQL_ENDPOINT, getDistrictQuery, queryVariables, headers);
    return resp
  } catch (error) {
    console.log(error);
    return error
  }
};

export const loadAllDistricts = async (realmUser) => {
  // GraphQL query to fetch all district information.
  const getAllDistrictsQuery = gql`
    query {
      districts {
        _id
        name
        schools
        superadmins
      }
    }
  `;

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getAllDistrictsQuery, null, headers);
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};



  // student section
export const updateStudentCount = async (user, incrementValue, realmUser) => {
  const currentCount = user.count;
  const newCount = currentCount + incrementValue;
  return await updateProfile(user.email, realmUser, { count: newCount });
};

export const fetchStudentCount = async (userID, realmUser) => {
  const getWellnessByUserIDQuery = gql`
    query GetWellnessDataByUserID($userID: String!) {
      user(query: { userID: $userID }) {
        count
      }
    }
  `;

  const queryVariables = { "userID": userID };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getWellnessByUserIDQuery, queryVariables, headers);
    return resp;  // Assuming the returned data has this structure.
  } catch (error) {
    console.log(error);
    return error;
  }
};

  // Wellness Document Section

  export const loadWellnesses = async (data, realmUser) => {
    // GraphQL query to fetch mode analytics as well as the category analytics.
    const getDistrictQuery = gql`
    query loadWellnessData ($data:WellnessQueryInput!){
      wellnesses (limit:100000,query:$data,sortBy:DATE_DESC){
        _id
        userID
        date
        helpful
        emotion
        action
      }
    }
    `;
    const queryVariables = { "data": data }
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
    try {
      const resp = await request(GRAPHQL_ENDPOINT, getDistrictQuery, queryVariables, headers);
      return resp
    } catch (error) {
      console.log(error);
      return error
    }
  };

  export const insertWellness = async (wellnessData, user, realmUser) => {
    // Including the userID from realmUser into the wellnessData
    const enrichedWellnessData = {
        ...wellnessData,
        userID: user._id
    };

    const insertWellnessQuery = gql`
      mutation($data: WellnessInsertInput!) {
        insertOneWellness(data: $data){
          _id
          action
          date
          emotion
          helpful
          userID
        }
      }
    `;

    // Using enrichedWellnessData instead of the original wellnessData
    const queryVariables = { "data": enrichedWellnessData };
  
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  
    try {
      const resp = await request(GRAPHQL_ENDPOINT, insertWellnessQuery, queryVariables, headers);
      return resp;
    } catch (error) {
      console.log(error);
      return error;
    }
};


export async function updateWellnessHelpfulMutation(id, helpful, realmUser) {
  const updateWellnessHelpfulQuery = gql`
    mutation UpdateWellnessHelpful($id: ObjectId!, $helpful: Boolean!) {
      updateOneWellness(query: { _id: $id }, set: { helpful: $helpful }) {
        _id
        helpful
      }
    }
  `;

  const queryVariables = {
    id: id,
    helpful: helpful
  };

  // Assuming realmUser contains required credentials
  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, updateWellnessHelpfulQuery, queryVariables, headers);
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
}

// DATA TRENDS SECTION
export const getWellnessDataByUserID = async (userID, realmUser) => {
  const getWellnessByUserIDQuery = gql`
    query GetWellnessDataByUserID($userID: String!) {
      wellnesses(query: { userID: $userID }) {
        _id
        action
        date
        emotion
        helpful
      }
    }
  `;

  const queryVariables = { "userID": userID };

  const headers = { Authorization: `Bearer ${realmUser._accessToken}` };

  try {
    const resp = await request(GRAPHQL_ENDPOINT, getWellnessByUserIDQuery, queryVariables, headers);
    return resp.wellnesses;  // Assuming the returned data has this structure.
  } catch (error) {
    console.log(error);
    return error;
  }
};




//////////////////////////


  export const updateEmotionCount = async (email, realmUser, emotion) => {
    const mutation = gql`
    mutation UpdateEmotionCount($email: String!, $emotionField: String!) {
      updateStudent(query: {email: $email}, inc: { [$emotionField]: 1 }) {
        counts
      }
    }
    `;
  
    const variables = {
      email: email,
      emotionField: `counts.${emotion}`
    };
  
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
  
    try {
      const resp = await request(GRAPHQL_ENDPOINT, mutation, variables, headers);
      return resp;
    } catch (error) {
      console.log(error);
      if(error.response.error_code==="InvalidSession"){
        return "InvalidSession";
      }
      return error;
    }
  };
  
  export const loadDistricts = async (realmUser) => {
    // GraphQL query to fetch mode analytics as well as the category analytics.
    const getDistrictQuery = gql`
    query {
      districts {
        name
        schools
        superadmins
      }
    }
    `;
    const queryVariables = {}
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
    try {
      const resp = await request(GRAPHQL_ENDPOINT, getDistrictQuery, queryVariables, headers);
      return resp
    } catch (error) {
      console.log(error);
      return error
    }
  };

  export const insertDistrict = async (districtData, realmUser) => {
    // GraphQL query to fetch mode analytics as well as the category analytics.
    const insertDistrictQuery = gql`
      mutation($data:DistrictInsertInput!) {
        insertOneDistrict(data: $data){
          name
        }
      }
      `;
    const queryVariables = { "data": districtData }
  
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
    //Handle Session Token expired. - Sign out on session token expired.
    try {
      const resp = await request(GRAPHQL_ENDPOINT, insertDistrictQuery, queryVariables, headers);
      return resp
    } catch (error) {
      console.log(error);
      return error
    }
  };

  export const deleteDistrict = async (districtName, realmUser) => {
    // GraphQL query to fetch mode analytics as well as the category analytics.
    const deleteDistrictQuery = gql`
      mutation($data:DistrictQueryInput!) {
        deleteOneDistrict(query:$data){
          name
        }  
      }
      `;
    const queryVariables = { "data": {"name": districtName } }
  
    const headers = { Authorization: `Bearer ${realmUser._accessToken}` };
    //Handle Session Token expired. - Sign out on session token expired.
    try {
      const resp = await request(GRAPHQL_ENDPOINT, deleteDistrictQuery, queryVariables, headers);
      return resp
    } catch (error) {
      console.log(error);
      return error
    }
  };
  