import {
  FaHome,
  FaUser,
  FaBook,
  FaCog,
  FaVolumeMute,
  FaVolumeUp,
  FaChartBar,
  FaSchool,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./BlueSidebar.css";
import { Context2 } from "./Audio";
import { authentication, firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const BlueSidebar = (props) => {
  const [playing, setPlaying] = useContext(Context2);
  const [user, setUser] = useState("student");
  const navigate = useNavigate();
  const location = useLocation();
  async function changeAudio(audio) {
    if (location.pathname === "/deepbreathe") {
      navigate("/deepbreathecopy");
    } else { 
      navigate(location.pathname);
    }
    setPlaying(audio);
  }
  async function getUserData() {
    const request = "students/" + authentication.currentUser.email;
    const document = await getDoc(doc(firestore, request));
    setUser(document.data().user);
  }

  useEffect(() => {
    //getUserData();
    setUser(props.user.user.user)
  }, []);

  return (
    <div class="bluesidebar">
       <div class='sidebarcolumn'>
      <div class='sidebarrow'>
      <Link to="/">
        <img
          class="bluelogo"
          src="../images/123WellnessLogo green-02.png"
          alt="1-2-3 Wellness"
        loading="lazy"/>
      </Link>
      {user === "student" && (
        <>
          <div class="bluestyleiconstudent">
            <Link to="/" style={{ color: "white" }}>
              <FaHome></FaHome>
            </Link>
          </div>
          <div class="bluestyleiconstudent">
            <Link to="/profilescreen" style={{ color: "white" }}>
              <FaUser></FaUser>
            </Link>
          </div>
          <div class="bluestyleiconstudent">
            <Link to="/optionsscreen" style={{ color: "white" }}>
              <FaCog></FaCog>
            </Link>
          </div>
          {playing === true ? (
            <div
              onClick={() => changeAudio(false)}
              class="bluestyleiconstudent"
            >
              <FaVolumeUp></FaVolumeUp>
            </div>
          ) : (
            <div onClick={() => changeAudio(true)} class="bluestyleiconstudent">
              <FaVolumeMute></FaVolumeMute>
            </div>
          )}
        </>
      )}
      {user === "teacher" && (
        <>
          <div class="bluestyleiconteacher">
            <Link to="/" style={{ color: "white" }}>
              <FaHome></FaHome>
            </Link>
          </div>
          <div class="bluestyleiconteacher">
            <Link to="/profilescreen" style={{ color: "white" }}>
              <FaUser></FaUser>
            </Link>
          </div>
          <div class="bluestyleiconteacher">
            <Link to="/studentsscreen" style={{ color: "white" }}>
              <FaBook></FaBook>
            </Link>
          </div>
          <div class="bluestyleiconteacher">
            <Link to="/optionsscreen" style={{ color: "white" }}>
              <FaCog></FaCog>
            </Link>
          </div>
          {playing === true ? (
            <div
              onClick={() => changeAudio(false)}
              class="bluestyleiconteacher"
            >
              <FaVolumeUp></FaVolumeUp>
            </div>
          ) : (
            <div onClick={() => changeAudio(true)} class="bluestyleiconteacher">
              <FaVolumeMute></FaVolumeMute>
            </div>
          )}
        </>
      )}
      {user === "admin" && (
        <>
          <div class="bluestyleiconadmin">
            <Link to="/" style={{ color: "white" }}>
              <FaHome></FaHome>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/profilescreen" style={{ color: "white" }}>
              <FaUser></FaUser>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/datatrends" style={{ color: "white" }}>
              <FaChartBar></FaChartBar>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/studentsscreen" style={{ color: "white" }}>
              <FaBook></FaBook>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/optionsscreen" style={{ color: "white" }}>
              <FaCog></FaCog>
            </Link>
          </div>
          {playing === true ? (
            <div onClick={() => changeAudio(false)} class="bluestyleiconadmin">
              <FaVolumeUp></FaVolumeUp>
            </div>
          ) : (
            <div onClick={() => changeAudio(true)} class="bluestyleiconadmin">
              <FaVolumeMute></FaVolumeMute>
            </div>
          )}
        </>
      )}
      {user === "superadmin" && (
        <>
          <div class="bluestyleiconadmin">
            <Link to="/" style={{ color: "white" }}>
              <FaHome></FaHome>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/profilescreen" style={{ color: "white" }}>
              <FaUser></FaUser>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/superadmindatatrends" style={{ color: "white" }}>
              <FaChartBar></FaChartBar>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/schoolsscreen" style={{ color: "white" }}>
              <FaSchool></FaSchool>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/optionsscreen" style={{ color: "white" }}>
              <FaCog></FaCog>
            </Link>
          </div>
          {playing === true ? (
            <div onClick={() => changeAudio(false)} class="bluestyleiconadmin">
              <FaVolumeUp></FaVolumeUp>
            </div>
          ) : (
            <div onClick={() => changeAudio(true)} class="bluestyleiconadmin">
              <FaVolumeMute></FaVolumeMute>
            </div>
          )}
        </>
      )}
      {user === "123wellness" && (
        <>
          <div class="bluestyleiconadmin">
            <Link to="/" style={{ color: "white" }}>
              <FaHome></FaHome>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/profilescreen" style={{ color: "white" }}>
              <FaUser></FaUser>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/wellnessstudents" style={{ color: "white" }}>
              <FaBook></FaBook>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/wellnessdatatrends" style={{ color: "white" }}>
              <FaChartBar></FaChartBar>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/wellnessadministrator" style={{ color: "white" }}>
              <FaSchool></FaSchool>
            </Link>
          </div>
          <div class="bluestyleiconadmin">
            <Link to="/optionsscreen" style={{ color: "white" }}>
              <FaCog></FaCog>
            </Link>
          </div>
          {playing === true ? (
            <div onClick={() => changeAudio(false)} class="bluestyleiconadmin">
              <FaVolumeUp></FaVolumeUp>
            </div>
          ) : (
            <div onClick={() => changeAudio(true)} class="bluestyleiconadmin">
              <FaVolumeMute></FaVolumeMute>
            </div>
          )}
        </>
      )}
      </div>
      <div class='patentpending'>Patent Pending</div>
      <div class='growthwell'>© Growthwell LLC 2023</div>
      </div>
    </div>
  );
};
export default BlueSidebar;
