import "./DataTrendsScreen.css";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useEffect, useState } from "react";
import moment from "moment";
import {loadSchool, loadClasses, loadWellnesses, loadProfiles} from "../../../realm/graphqlQueries";
import { useContext } from "react";
import { UserContext } from "../../../realm/user.context";
import { CircularProgress } from "@mui/material";

const DataTrendsScreen = (props) => {
  const [classes, setClasses] = useState({});
  const [chosenClass, setChosenClass] = useState("Choose Class");
  const [chosenTime, setChosenTime] = useState("month");
  const [chosenTimeCompare, setChosenTimeCompare] = useState("month");
  const [allWellnessPoints, setAllWellnessPoints] = useState([]);
  const [counter, setCounter] = useState(0);
  const [studentData, setStudentData] = useState({});
  const [threeImpactfulActions, setThreeImpactfulActions] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterAmountCurrent, setFilterAmountCurrent] = useState(
    moment.duration(30, "days")
  );
  const [filterAmountCompare, setFilterAmountCompare] = useState(
    moment.duration(30, "days")
  );

  const [counts, setCounts] = useState([
    { name: "sad", current: 0, compare: 0 },
    { name: "tired", current: 0, compare: 0 },
    { name: "lonely", current: 0, compare: 0 },
    { name: "happy", current: 0, compare: 0 },
    { name: "grateful", current: 0, compare: 0 },
    { name: "calm", current: 0, compare: 0 },
    { name: "stressed", current: 0, compare: 0 },
    { name: "scared", current: 0, compare: 0 },
    { name: "angry", current: 0, compare: 0 },
  ]);

  const { realmUser } = useContext(UserContext);

  // sets classes state variable with all classes in that school
  async function getAllData() {
    setLoading(true)
    //getWellnessData(props.user.user, realmUser)
    let studentdata = {};
    let teaching = {};
    let countertemp = 0;
    // get the school this current account is teaching at
    const schoolRef = await loadSchool({name:props.user.user.school,district:props.user.user.district},realmUser)
    let lclasses = schoolRef.classes
    //create a list of all students:
    const classRef = await loadClasses(props.user.user.school,lclasses,realmUser)
    let studentList = []
    classRef.classes.forEach(cls=>{
      teaching[cls.name]=cls.students
      studentList = studentList.concat(cls.students)
    })
    //make Union
    const allStudents = [...new Set(studentList)]
    //Get All Wellnessdata for students.
    let allwellnesspoints =[]
    const studentRef = await loadProfiles(allStudents,realmUser)
    const allStudentIDs = studentRef.users.map(x=>x._id)
    let wellnessRef = await loadWellnesses({userID_in:allStudentIDs},realmUser)
    //TODO - make sure date format is consistent then this is no longer necessary
    wellnessRef.wellnesses.forEach(datapoint=>{
      datapoint.date = Date.parse(datapoint.date)
      allwellnesspoints.push(datapoint)
    })
    // make student data, an object with student name
    studentRef.users.forEach(student=>{
      let relWellness = wellnessRef.wellnesses.filter(x=>x.userID===student._id)
      //console.log("Here is the way ",relWellness)
      if(relWellness===null || relWellness===undefined){
        studentdata[student.email]=[]
      }else{
      studentdata[student.email]=relWellness
      }
    })
    setCounter(countertemp);
    setAllWellnessPoints(allwellnesspoints);
    setStudentData(studentdata);
    //console.log(teaching,studentdata)
    setClasses(teaching);
    setLoading(false)
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const barColors = ["#5b9cd6", "#4cc68d", "#bd92e4"];
  const barColorsGray = ["#D3D3D3"];
  function transform(compareDict, currentDict) {
    var counter = 0;
    var tempcounts = [];
    Object.keys(compareDict).forEach((emotion) => {
      counter += currentDict[emotion];
      tempcounts.push({
        name: emotion,
        current: currentDict[emotion],
        compare: compareDict[emotion],
      });
    });
    setCounter(counter);
    setCounts(tempcounts);
  }
  // manipulate counts to a form that filters by that className
  function changeClassFilter(classname) {
    let currentDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let compareDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    var impactfulactions = {};
    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(
      filterAmountCurrent + filterAmountCompare
    );
    let currentStudents = []
    let currentEmptyStudents = []

    if (classname === "Choose Class") console.log("Choose Class");
    else if (classname === "All Classes") {
      allWellnessPoints.forEach((datapoint) => {
        if (datapoint.date >= currentDate.valueOf()) {
          currentDict[datapoint.emotion] += 1;
          if (impactfulactions[datapoint.action] && datapoint.helpful)
            impactfulactions[datapoint.action] += 1;
          else if(datapoint.helpful)impactfulactions[datapoint.action] = 1;
        } else if (datapoint.date >= compareDate.valueOf())
          compareDict[datapoint.emotion] += 1;
      });
    } else if (classes[classname]) {
      classes[classname].forEach((student) => {
        if(studentData[student]){
          currentStudents.push(studentData[student])
          studentData[student].forEach((datapoint) => {
          if (datapoint.date >= currentDate.valueOf()) {
            currentDict[datapoint.emotion] += 1;
            if (impactfulactions[datapoint.action] && datapoint.helpful)
            impactfulactions[datapoint.action] += 1;
          else if(datapoint.helpful)impactfulactions[datapoint.action] = 1;
          } else if (datapoint.date >= compareDate.valueOf())
            compareDict[datapoint.emotion] += 1;
        });
      }
      else{
        currentEmptyStudents.push(studentData[student])
      }
      });
    }
    
    // transform takes the two dicts and manipulates counts
    transform(compareDict, currentDict);
    transformActions(impactfulactions);
    setChosenClass(classname);
  }
  function transformActions(impactfulactions) {
    const num = 3;
    const requiredObj = {};
    if (Object.keys(impactfulactions).length < 1) {
      setThreeImpactfulActions(requiredObj);
      return;
    }
    Object.keys(impactfulactions)
      .sort((a, b) => impactfulactions[b] - impactfulactions[a])
      .forEach((key, ind) => {
        if (ind < num) {
          requiredObj[key] = impactfulactions[key];
        }
      });
    setThreeImpactfulActions(requiredObj);
    //  return requiredObj;
  }

  function timeHelper(time) {
    if (time === "day") return moment.duration(24, "hours");
    if (time === "week") return moment.duration(7, "days");
    if (time === "month") return moment.duration(30, "days");
    if (time === "3 months") return moment.duration(90, "days");
    if (time === "6 months") return moment.duration(182, "days");
    if (time === "year") return moment.duration(365, "days");
  }
  function changeTimeFilter(time) {
    const filtertime = timeHelper(time);
    let currentDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let compareDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    var impactfulactions = {};
    const currentDate = moment().subtract(filtertime);
    const compareDate = moment().subtract(filtertime + filterAmountCompare);
    if (chosenClass === "Choose Class") console.log("Choose Class");
    else if (chosenClass === "All Classes") {
      allWellnessPoints.forEach((datapoint) => {
        if (new Date(datapoint.date).valueOf() >= currentDate.valueOf()) {
          currentDict[datapoint.emotion] += 1;
          if (impactfulactions[datapoint.action] && datapoint.helpful)
          impactfulactions[datapoint.action] += 1;
        else if(datapoint.helpful)impactfulactions[datapoint.action] = 1;
        } else if (new Date(datapoint.date).valueOf() >= compareDate.valueOf())
          compareDict[datapoint.emotion] += 1;
      });
    } else {
      classes[chosenClass].forEach((student) => {
        studentData[student].forEach((datapoint) => {
          if (new Date(datapoint.date).valueOf() >= currentDate.valueOf()) {
            currentDict[datapoint.emotion] += 1;
            if (impactfulactions[datapoint.action] && datapoint.helpful)
            impactfulactions[datapoint.action] += 1;
          else if(datapoint.helpful)impactfulactions[datapoint.action] = 1;
          } else if (new Date(datapoint.date).valueOf() >= compareDate.valueOf())
            compareDict[datapoint.emotion] += 1;
        });
      });
    }
    // transform takes the two dicts and manipulates counts
    transform(compareDict, currentDict);
    transformActions(impactfulactions);
    setFilterAmountCurrent(filtertime);
    setChosenTime(time);
  }
  function changeTimeFilterCompare(time) {
    const filtertime = timeHelper(time);
    let currentDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let compareDict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(filterAmountCurrent + filtertime);
    if (chosenClass === "Choose Class") console.log("Choose Class");
    else if (chosenClass === "All Classes") {
      console.log(allWellnessPoints)
      allWellnessPoints.forEach((datapoint) => {
        if (new Date(datapoint.date).valueOf() >= currentDate.valueOf())
          currentDict[datapoint.emotion] += 1;
        else if (new Date(datapoint.date).valueOf() >= compareDate.valueOf())
          console.log(compareDict)
          compareDict[datapoint.emotion] += 1;
      });
      console.log(compareDict)
    } else {
      classes[chosenClass].forEach((student) => {
        studentData[student].forEach((datapoint) => {
          if (new Date(datapoint.date).valueOf() >= currentDate.valueOf())
            currentDict[datapoint.emotion] += 1;
          else if (new Date(datapoint.date).valueOf() >= compareDate.valueOf())
            compareDict[datapoint.emotion] += 1;
        });
      });
    }
    // transform takes the two dicts and manipulates counts
    transform(compareDict, currentDict);
    setFilterAmountCompare(filtertime);
    setChosenTimeCompare(time);
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      var comparetotal = 0;
    counts.forEach(data => {
      comparetotal += data.compare
    })
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`} </p>
          <p className="label">{`current:`}</p>
          <p className="labelindent">{`count: ${payload[0].value}, percentage: ${Math.round(payload[0].value/counter*100)}%`} </p>
          <p className="label">{`compare:`}</p>
          <p className="labelindent">{`count: ${payload[1].value}, percentage: ${Math.round(payload[1].value/comparetotal*100)}%`} </p>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="outerbackground">
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
      <div className="columndatatrends">
        {/* <div className="datatrendstitle">Data Trends</div> */}
        {chosenClass === "Choose Class" ? (
          <div className="practicedwellness">
            Choose a className to begin viewing your schools data trends!
          </div>
        ) : (
          <div className="practicedwellness">
            Congratulations! <span className='greenlettersdata'>{chosenClass}</span> practiced wellness <span className='greenlettersdata'>{counter} times</span> in
            the last <span className='greenlettersdata'>{chosenTime}</span>!
          </div>
        )}

        <div className="emotionsidentified">
          Emotions identified: this time period is in color, past is gray
        </div>
        <div className="bargraphdatatrends">
          <div className="labelrow">
          <div className="classrow">Class</div>
            <div className="currentrow">Current Data</div>
            <div className="previousrow">Compare Data</div>
          </div>
          <select
            className="filterprofiledata"
            value={chosenClass}
            onChange={(e) => changeClassFilter(e.target.value)}
          >
            <option value="Choose Class">Choose Class</option>
            <option value="All Classes">All Classes</option>
            {Object.keys(classes).length>0 &&
              Object.keys(classes).map((classname) => (
                <option key={[classname]} value={classname}>{classname}</option>
              ))}
          </select>{" "}
          <select
            className="changetimefilterdata"
            value={chosenTime}
            onChange={(e) => changeTimeFilter(e.target.value)}
          >
            <option value="day">Today (Last 24 hours)</option>
            <option value="week">Week (Last 7 days)</option>
            <option value="month">Month (Last 30 days)</option>
            <option value="3 months">3 Months (Last 3 months)</option>
            <option value="6 months">6 Months (Last 6 months)</option>
            <option value="year">1 Year (Last 12 months)</option>
          </select>{" "}
          <select
            className="changetimefilterdata"
            value={chosenTimeCompare}
            onChange={(e) => changeTimeFilterCompare(e.target.value)}
          >
            <option value="day">Previous day</option>
            <option value="week">Previous week</option>
            <option value="month">Previous Month</option>
            <option value="3 months">Previous 3 Months</option>
            <option value="6 months">Previous 6 Months</option>
            <option value="year">Previous Year</option>
          </select>{" "}
          <ResponsiveContainer width="90%" height="80%">
            <BarChart
              className='datatrendsbarchart'
              data={counts}
            >
              <Tooltip content={<CustomTooltip />} cursor={{ fill: "lightgray", fillOpacity: "30%" }} />
              <Bar barSize={50} dataKey="current">
                {counts.map((entry, index) => (
                  <Cell key={index} fill={barColors[Math.floor(index / 3)]} />
                ))}
              </Bar>
              <Bar barSize={50} dataKey="compare">
                {counts.map((entry, index) => (
                  <Cell key={index} fill={barColorsGray[0]} />
                ))}
              </Bar>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="name"
                minTickGap={-2}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        {Object.keys(threeImpactfulActions).length !== 0 ? (
          <div className="datatrendsrow">
            <div className="datatrendscolumn1">
              {/* <div className="emotionsidentified"> */}
              The most impactful wellness practices for your students in the
              last <span className='greenlettersdata'>{chosenTime}</span> are: {/* </div> */}
            </div>
            <div className="datatrendscolumn2">
              {Object.keys(threeImpactfulActions).map((action, ind) => (
                <div key={[action]} className="wellnesspractices">
                  {ind + 1}. {action}, which was used{" "}
                  {threeImpactfulActions[action]} time(s)
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="datatrendsempty">
            This className doesn't have a wellness history yet. Time to get started
            on the 1-2-3 Wellness journey!
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTrendsScreen;
