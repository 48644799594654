import "./SeeAndHear.css";
import BlueSidebar from "../../components/BlueSidebar";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";
import { Box, LinearProgress } from "@mui/material";

const SeeAndHear = (props) => {
  const navigate = useNavigate();
  const [playing, setPlaying] = useContext(Context2);
  const [progress, setProgress] = useState(0);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  
  useEffect(() => {
    const progresstimer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 1
      );
    }, 180);
    const timer = setTimeout(() => {
      navigate("/deepbreathe3");
    }, 18000);
    return () => {
      clearTimeout(timer);
      clearInterval(progresstimer);
    };
  }, []);

  return (
    // <AnimatedPage>

    <motion.div
      class="outer1"
      // initial={{ opacity: 1 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <div class='reactplayer'>
      <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/seeandhear.wav"} />
      </div>
      {/* <BlueSidebar></BlueSidebar> */}
      <div class="inner">
        <div class="seeandheartextrow">
        <img
            class="greennumberseeandhear"
            src="../images/green2.png"
            alt="loading..."
            loading="lazy"
          />
          <div class="see">&nbsp;What do I &#160;</div>
          <div class="seetext">SEE</div>
          <div class="see">&#160;&&#160; </div>
          <div class="heartext">HEAR?</div>
        </div>

        <div class="seeandhearrow">
          <img class="whatdoisee" src="../images/Eyes.gif" alt="loading..." loading="lazy"/>
          <img class="whatdoihear" src="../images/Ears.gif" alt="loading..." loading="lazy"/>
        </div>
        <div class="focusaway">Focus away from the screen</div>
        <Box className='timerbar' 
        >
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </div>
    </motion.div>
    // </AnimatedPage>
  );
};

export default SeeAndHear;
