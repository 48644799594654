import "./DeepBreathe.css";
import BlueSidebar from "../../components/BlueSidebar";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";
import { Context } from "../../components/Store";

const DeepBreathe3 = (props) => {
  const navigate = useNavigate();
  const [playing,setPlaying]= useContext(Context2);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/actions");
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <motion.div class="outerbreath1" 
    // initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}
    >
      {/* <BlueSidebar></BlueSidebar> */}
      <div class='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/deepbreathe3.wav"} />
      </div>
      <div class="outercolumnbreathe">
        <div class="text">Please take a deep breath</div>
        <img
          class="gif"
          src="../images/GIF_ThirdBreath_Crop.gif"
          alt="loading..."
          loading="lazy"
        />
      </div>
    </motion.div>
  );
};

export default DeepBreathe3;
