import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, OAuthProvider} from "firebase/auth";
import { authentication } from "../../firebase";
import GoogleButton from "../../components/GoogleButton";
import EmailButton from "../../components/EmailButton";
import EmailLogin from "../../components/EmailLogin";
import "./SigninScreen.css";
import {useState} from 'react';
import MicrosoftButton from "../../components/MicrosoftButton";
import { CircularProgress } from "@mui/material";


const SigninScreen = () => {
  const [emailClick, setEmailClicked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showEmailErr, setShowEmailErr] = useState(false);
  const [logErr, setLogErr] = useState(false);
  const [diffCredential, setDiffCredential] = useState(false);
  const [loading, setLoading] = useState(false);

  function signUserIn(email, password) {
    setLoading(true)
    signInWithEmailAndPassword(authentication, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log("Logged in user", user.email)
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, ": ", errorMessage)
      if (errorCode === "auth/invalid-email") {
        setShowEmailErr(true);
      } else if (errorCode === "auth/wrong-password") {
        setLogErr(true);
      } else {
        createUser(email, password);
      }
    });
    setLoading(false)
  }
  

  function createUser(email, password) {
    setLoading(true)
    createUserWithEmailAndPassword(authentication, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log("Created user", user.email)

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage)
      if (errorCode === "auth/invalid-email") {
        setShowEmailErr(true);
      } else {
        setLogErr(true);
      }
      // ..
    });
    setLoading(false)

  }
  

  const microProvider = new OAuthProvider('microsoft.com');
  microProvider.setCustomParameters({
  //   // Force re-consent.
    prompt: 'select_account',
  //   // Target specific email with login hint.
  //   // login_hint: ''
  });

  function MicrosoftLogin() {
    setLoading(true)
    signInWithPopup(authentication, microProvider)
    .then(()=>{//result) => {
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.

      // Get the OAuth access token and ID Token
      //const credential = OAuthProvider.credentialFromResult(result);
      //const accessToken = credential.accessToken;
      //const idToken = credential.idToken;
      console.log("this worked well")
    })
    .catch((error) => {
      console.log(error.code, " is the error")

      if (error.code === "auth/account-exists-with-different-credential") {
        console.log("this is a bust")
        setDiffCredential(true);
      }
      // Handle error.
    });
    setLoading(false)

  }

  // for some reason this isn't working reliably
  function sendPasswordReset(email) {
    sendPasswordResetEmail(authentication, email)
      .then(function() {
        console.log("email sent")
        setEmailSent(true);
      })
      .catch(function() {
        console.log("failed to send email")
      })
  }

  async function Signin(){
    console.log("this is the vibe")
    setLoading(true)
    console.log(loading)
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      //   // Force re-consent.
        prompt: 'select_account',
      //   // Target specific email with login hint.
      //   // login_hint: ''
      });
    signInWithPopup(authentication, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log("logged in " + token);
        // The signed-in user info.
        // const user = result.user
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error " + errorCode + " " + errorMessage);
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
      console.log(loading)

      setLoading(false)
  };

  return (
      <div className="outer">
        <img
          className="logo"
          src="../images/123WellnessLogo-01.png"
          alt="123 Wellness Logo"
          loading="lazy"
        ></img>
        {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
        {!diffCredential ? <div className="signin">Sign in to continue your wellness journey</div> : <div className="signin">Looks like either your email or password is incorrect. Try again or login with a different provider.</div>}
        {!emailClick ? <div style={{display: 'flex', flexDirection: 'column'}}>
          <button onClick={()=> {setLoading(true);Signin()}} style={{background: 'transparent', border: 'none', marginTop: "8vh", marginBottom: '2vh', borderRadius: 'initial'}}><GoogleButton /></button>
          <button onClick={() => MicrosoftLogin()} style={{background: 'transparent', border: 'none', borderRadius: 'initial', marginBottom: '2vh'}}><MicrosoftButton /></button>
          <button onClick={() => setEmailClicked(true)} style={{background: 'transparent', border: 'none', borderRadius: 'initial'}}><EmailButton /></button>
        </div> : <EmailLogin hideLogin={setEmailClicked} authUser={signUserIn} passwordReset={sendPasswordReset} emailErr={showEmailErr} handleEmailErr={setShowEmailErr} isErr={logErr} handleErr={setLogErr} emailSuccess={emailSent} handleEmailSuccess={setEmailSent}/>}
              <div style={{marginTop:'5vh'}}className='patentpending'>Patent Pending</div>
        <div className='growthwell'>© Growthwell LLC 2023</div>
        <div style={{display: 'flex', gap: '20px', }}>
          <div><a href="https://drewschwartz.com/privacy-policy" className="growthwell" target="_blank" rel="noreferrer">Privacy Policy</a></div>
          <div><a href="https://drewschwartz.com/terms-of-service" className="growthwell" target="_blank" rel="noreferrer">Terms of Service</a></div>
        </div>
        <img
          className="bottomdesign"
          src="../images/overlaping colors.png"
          alt="design"
          loading="lazy"
        ></img>
      </div>
  );
};
export default SigninScreen;
