import { useState } from 'react';

export default function EmailLogin(props) {

  var [password, setPassword] = useState("");
  var [email, setEmail] = useState("");

 function signInUser() {
    props.authUser(email, password);
 }

  return (
    <div id="outerEmailBackground">
      {props.isErr && <div style={{color: 'red', marginBottom: '10px'}}>Looks like either your email or password is incorrect. Try again or login with a different provider.</div>}
      <label className='labelText'>Email<span style={{color: 'red'}}> *</span></label>
      <input style={{marginBottom: '20px'}} className='emailLoginInputs' type="email" placeholder='Email' onChange={(e) => {setEmail(e.target.value)}} required/>
      {props.emailErr && <div style={{color:'red', marginTop: '-20px', marginBottom: '10px'}}>Please input a valid email</div>}
      <label className='labelText'>Password<span style={{color: 'red'}}> *</span></label>
      <input style={{marginBottom: '20px'}} className='emailLoginInputs' type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} required/>
      <p style={{ fontSize: '10pt', marginTop: '-20px'}}>First time logging in? Set your password now.</p>
      <div style={{width: '95%', display: 'flex', justifyContent: 'flex-start'}}>
        <button id="cancelButton" onClick={() => {props.hideLogin(false); props.handleErr(false); props.handleEmailErr(false); props.handleEmailSuccess(false)}}>Cancel</button>
        <button id="signInButton" onClick={() => signInUser()}>Sign In</button>
      </div>
      <div id="forgotPass" style={{ textDecoration: 'underline', color: 'blue', marginTop: '10px'}} onClick={() => props.passwordReset(email)}>Forgot password?</div>
      {props.emailSuccess && <div>Reset Instructions sent to email entered above.</div>}
    </div>
  )
}