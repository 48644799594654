// import "./DataTrendsScreen.css";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useEffect, useState } from "react";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../../realm/user.context";
import { loadAllDistricts, loadProfiles2, loadWellnesses } from "../../../realm/graphqlQueries";
import { CircularProgress } from "@mui/material";

const WellnessDataTrendsScreen = (props) => {
  const [chosenSchool, setChosenSchool] = useState("Choose School");
  const [chosenDistrict, setChosenDistrict] = useState("Choose District");
  const [districts, setDistricts] = useState({});
  const [chosenTime, setChosenTime] = useState("month");
  const [chosenTimeCompare, setChosenTimeCompare] = useState("month");
  const [allWellnessPoints, setAllWellnessPoints] = useState([]);
  const [counter, setCounter] = useState(0);
  const [studentData, setStudentData] = useState({});
  const [threeImpactfulActions, setThreeImpactfulActions] = useState({});
  const [filterAmountCurrent, setFilterAmountCurrent] = useState(
    moment.duration(30, "days")
  );
  const [filterAmountCompare, setFilterAmountCompare] = useState(
    moment.duration(30, "days")
  );

  const [feeling, setFeeling] = useState("select");
  const [wellnessList, setWellnessList] = useState([]);
  const [loading, setLoading] = useState(true);


  const [counts, setCounts] = useState([
    { name: "sad", current: 0, compare: 0 },
    { name: "tired", current: 0, compare: 0 },
    { name: "lonely", current: 0, compare: 0 },
    { name: "happy", current: 0, compare: 0 },
    { name: "grateful", current: 0, compare: 0 },
    { name: "calm", current: 0, compare: 0 },
    { name: "stressed", current: 0, compare: 0 },
    { name: "scared", current: 0, compare: 0 },
    { name: "angry", current: 0, compare: 0 },
  ]);

  const { realmUser } = useContext(UserContext);

  // sets classes state variable with all classes in that school
  async function getAllData() {
    setLoading(true)
    var studentdata = {};
    var allwellnesspoints = [];
    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(filterAmountCompare + filterAmountCurrent);
    let countertemp = 0;

    let currentDictionary = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let compareDictionary = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    // 1. Load all districts.
    let districtsRaw = (await loadAllDistricts(realmUser)).districts;
    const districts = {};

    // 2. Load all students.
    const allStudents = await loadProfiles2({ "user": "student" }, realmUser);

    // 3. Load all wellness data.
    const allWellnessDataBulk = await loadWellnesses({}, realmUser)

    let wellnessMap = {};
    let recordCounter = 0;
    for (const record of allWellnessDataBulk.wellnesses) {
      const userID = record.userID;
      if (!wellnessMap[userID]) {
        wellnessMap[userID] = [];
      }
      wellnessMap[userID].push(record);
      recordCounter+=1
    }
    console.log("Users practiced a total of ", recordCounter, "all students: ", allStudents.users.length)

    districtsRaw.forEach(districtItem => {
      // Create district in our districts object.
      console.log(districtItem.name)
      districts[districtItem.name] = {};

      if (districtItem.schools) {
        districtItem.schools.forEach(schoolName => {
          districts[districtItem.name][schoolName] = [];

          // Find the students in this school.
          const studentsInSchool = allStudents.users.filter(student => student.school === schoolName);
          studentsInSchool.forEach(student => {
            if (student.email) {
              districts[districtItem.name][schoolName].push(student.email);
              // Add wellness data for the student.
              studentdata[student.email] = wellnessMap[student._id] || [];
              studentdata[student.email].forEach(datapoint => {
                allwellnesspoints.push(datapoint);
                let datapointTimestamp = new Date(datapoint.date).valueOf();
                if (datapointTimestamp >= currentDate.valueOf()) {
                  currentDictionary[datapoint.emotion] += 1;
                } else if (datapointTimestamp >= compareDate.valueOf()) {
                  compareDictionary[datapoint.emotion] += 1;
                }
              });
            }
          });
        });
      }
    });
    setDistricts(districts);
    console.log(districts)
    //transform(compareDictionary, currentDictionary);
    setCounter(countertemp);
    setAllWellnessPoints(allwellnesspoints);
    setStudentData(studentdata);
    setLoading(false)

  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const barColors = ["#5b9cd6", "#4cc68d", "#bd92e4"];
  const barColorsGray = ["#D3D3D3"];
  function transform(compareDict, currentDict) {
    var counter = 0;
    var tempcounts = [];
    Object.keys(compareDict).forEach((emotion) => {
      counter += currentDict[emotion];
      tempcounts.push({
        name: emotion,
        current: currentDict[emotion],
        compare: compareDict[emotion],
      });
    });
    setCounter(counter);
    setCounts(tempcounts);
  }
  // manipulate counts to a form that filters by that class

  async function gatherWellnessPractices(feeling, time, district) {
    setChosenTime(time);

    const currentDate = moment().subtract(timeHelper(time));
    const emotionDict = {
      "sad": {},
      "tired": {},
      "lonely": {},
      "happy": {},
      "grateful": {},
      "calm": {},
      "stressed": {},
      "scared": {},
      "angry": {}
    };
    const allStudents = await loadProfiles2({ "user": "student" }, realmUser)

    const processDataPoint = (datapoint) => {
      let datapointTimestamp = new Date(datapoint.date).valueOf();
      if (datapointTimestamp >= currentDate.valueOf() && datapoint.helpful) {
        let feeling = datapoint.emotion;
        let action = datapoint.action;

        if (!emotionDict[feeling][action]) {
          emotionDict[feeling][action] = 0;
        }

        emotionDict[feeling][action]++;
      }
    }

    if (district === "All Districts") {
      allWellnessPoints.forEach(processDataPoint);
    } else {
      allStudents.users.forEach(student => {
        const studentEmail = student.email;
        if (studentData[studentEmail]) {
          studentData[studentEmail].forEach(processDataPoint);
        }
      });
    }



    // studentData
    // //consider adding the below line to the districts filter before the map thing
    //   .filter((data) => data.date >= moment().subtract(filter).valueOf())
    //   .map((datapoint) => {

    //     let feeling = datapoint.emotion
    //     let helped = datapoint.helpful
    //     let action = datapoint.action

    //     if (helped) {
    //       if (! (action in emotionDict[feeling] )) {
    //         emotionDict[feeling][action] = 0
    //       }
    //       emotionDict[feeling][action] ++;
    //     }
    //   });

    var feelingList;
    setFeeling(feeling)
    if (feeling === "select") {
      feelingList = [];
    } else {
      feelingList = Array.from(Object.keys(emotionDict[feeling]));
    }

    if (feelingList.length > 3) {
      let objectList = Object.keys(emotionDict[feeling]).sort(function (a, b) { return emotionDict[feeling][b] - emotionDict[feeling][a] })
      setWellnessList(objectList.slice(0, 3))
    } else {
      setWellnessList(feelingList)
    }
  }

  function transformActions(impactfulactions) {
    const num = 3;
    const requiredObj = {};
    if (Object.keys(impactfulactions).length < 1) {
      setThreeImpactfulActions(requiredObj);
      return;
    }
    Object.keys(impactfulactions)
      .sort((a, b) => impactfulactions[b] - impactfulactions[a])
      .forEach((key, ind) => {
        if (ind < num) {
          requiredObj[key] = impactfulactions[key];
        }
      });
    setThreeImpactfulActions(requiredObj);
    //  return requiredObj;
  }

  function timeHelper(time) {
    if (time === "day") return moment.duration(24, "hours");
    if (time === "week") return moment.duration(7, "days");
    if (time === "month") return moment.duration(30, "days");
    if (time === "3 months") return moment.duration(90, "days");
    if (time === "6 months") return moment.duration(182, "days");
    if (time === "year") return moment.duration(365, "days");
  }
  function changeTimeFilter(time) {
    const filtertime = timeHelper(time);
    let currentDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let compareDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    var impactfulactions = {};
    const currentDate = moment().subtract(filtertime);
    const compareDate = moment().subtract(filtertime + filterAmountCompare);
    var addedStudents = [];
    if (chosenDistrict === "All Districts") {
      allWellnessPoints.forEach((datapoint) => {
        let datapointTimestamp = new Date(datapoint.date).valueOf();

        if (datapointTimestamp >= currentDate.valueOf()) {
          currentDict[datapoint.emotion] += 1;
          if (impactfulactions[datapoint.action] && datapoint.helpful)
            impactfulactions[datapoint.action] += 1;
          else if (datapoint.helpful) impactfulactions[datapoint.action] = 1;
        } else if (datapointTimestamp >= compareDate.valueOf())
          compareDict[datapoint.emotion] += 1;
      });
    } else if (chosenSchool === "All Schools") {
      Object.keys(districts[chosenDistrict]).forEach((schoolname) =>
        districts[chosenDistrict][schoolname].forEach((student) => {
          if (studentData[student] && !addedStudents.includes(student)) {
            addedStudents.push(student);
            studentData[student].forEach((datapoint) => {
              let datapointTimestamp = new Date(datapoint.date).valueOf();

              if (datapointTimestamp >= currentDate.valueOf()) {
                currentDict[datapoint.emotion] += 1;
                if (impactfulactions[datapoint.action] && datapoint.helpful)
                  impactfulactions[datapoint.action] += 1;
                else if (datapoint.helpful)
                  impactfulactions[datapoint.action] = 1;
              } else if (datapointTimestamp >= compareDate.valueOf())
                compareDict[datapoint.emotion] += 1;
            });
          }
        })
      );
    } else {
      districts[chosenDistrict][chosenSchool].forEach((student) => {
        if (studentData[student] && !addedStudents.includes(student)) {
          addedStudents.push(student);
          studentData[student].forEach((datapoint) => {
            let datapointTimestamp = new Date(datapoint.date).valueOf();

            if (datapointTimestamp >= currentDate.valueOf()) {
              currentDict[datapoint.emotion] += 1;
              if (impactfulactions[datapoint.action] && datapoint.helpful)
                impactfulactions[datapoint.action] += 1;
              else if (datapoint.helpful)
                impactfulactions[datapoint.action] = 1;
            } else if (datapointTimestamp >= compareDate.valueOf())
              compareDict[datapoint.emotion] += 1;
          });
        }
      });
    }
    // transform takes the two dicts and manipulates counts
    transform(compareDict, currentDict);
    transformActions(impactfulactions);
    setFilterAmountCurrent(filtertime);
    setChosenTime(time);
  }
  function changeTimeFilterCompare(time) {
    const filtertime = timeHelper(time);
    let currentDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let compareDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(filterAmountCurrent + filtertime);

    var addedStudents = [];
    if (chosenDistrict === "All Districts") {
      allWellnessPoints.forEach((datapoint) => {
        let datapointTimestamp = new Date(datapoint.date).valueOf();

        if (datapointTimestamp >= currentDate.valueOf()) {
          currentDict[datapoint.emotion] += 1;
        } else if (datapointTimestamp >= compareDate.valueOf())
          compareDict[datapoint.emotion] += 1;
      });
    } else if (chosenSchool === "All Schools") {
      Object.keys(districts[chosenDistrict]).forEach((schoolname) =>
        districts[chosenDistrict][schoolname].forEach((student) => {
          if (studentData[student]&& !addedStudents.includes(student)) {
            addedStudents.push(student);
            studentData[student].forEach((datapoint) => {
              let datapointTimestamp = new Date(datapoint.date).valueOf();

              if (datapointTimestamp >= currentDate.valueOf()) {
                currentDict[datapoint.emotion] += 1;
              } else if (datapointTimestamp >= compareDate.valueOf())
                compareDict[datapoint.emotion] += 1;
            });
          }
        })
      );
    } else {
      districts[chosenDistrict][chosenSchool].forEach((student) => {
        if (studentData[student] && !addedStudents.includes(student)) {
          addedStudents.push(student);
          studentData[student].forEach((datapoint) => {
            let datapointTimestamp = new Date(datapoint.date).valueOf();

            if (datapointTimestamp >= currentDate.valueOf()) {
              currentDict[datapoint.emotion] += 1;
            } else if (datapointTimestamp >= compareDate.valueOf())
              compareDict[datapoint.emotion] += 1;
          });
        }
      });
    }
    // transform takes the two dicts and manipulates counts
    transform(compareDict, currentDict);
    setFilterAmountCompare(filtertime);
    setChosenTimeCompare(time);
  }

  const getWellnessDataForDistrict = (districtName, districts, studentdata)=>{
    let wellnessDataForDistrict = [];

    // Check if the district exists.
    if (!districts[districtName]) {
      console.error(`District ${districtName} not found.`);
      return wellnessDataForDistrict;
    }
    // Iterate through all schools in the district.
    for (const schoolName in districts[districtName]) {
      // For each school, get the students' email addresses.
      const studentsInSchool = districts[districtName][schoolName];
      console.log(schoolName,studentsInSchool)

      // For each student, get their wellness data and add it to the district's wellness data array.
      studentsInSchool.forEach(email => {
        const studentWellnessData = studentdata[email];
        if (studentWellnessData) {
          wellnessDataForDistrict.push(studentWellnessData)
        }
      });
    }
    const wl = wellnessDataForDistrict.flat()
    return wl;
  }

  function changeDistrictFilter(district) {
    console.log(district)
    let currentDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let compareDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let impactfulactions = {};

    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(filterAmountCurrent + filterAmountCompare);

    const processDataPoint = (datapoint) => {
      let datapointTimestamp = new Date(datapoint.date).valueOf();

      if (datapointTimestamp >= currentDate.valueOf()) {
        currentDict[datapoint.emotion] += 1;
        if (datapoint.helpful) {
          impactfulactions[datapoint.action] = (impactfulactions[datapoint.action] || 0) + 1;
        }
      } else if (datapointTimestamp >= compareDate.valueOf()) {
        compareDict[datapoint.emotion] += 1;
      }
    }

    if (district === "Choose District") {
      console.log("Choose District");
    } else if (district === "All Districts") {
      allWellnessPoints.forEach(processDataPoint);
    } else {
      // get users from district:
      const wellnessDataForDistrict = getWellnessDataForDistrict(district, districts, studentData);
      if (wellnessDataForDistrict) {
        //console.log(wellnessDataForDistrict)
        wellnessDataForDistrict.forEach(processDataPoint);
      }
    }

    transform(compareDict, currentDict);
    transformActions(impactfulactions);
    setChosenDistrict(district);
    setChosenSchool("All Schools");
  }


  function changeSchoolFilter(school) {
    let currentDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let compareDict = {
      sad: 0, tired: 0, lonely: 0, happy: 0, grateful: 0, calm: 0, stressed: 0, scared: 0, angry: 0
    };
    let impactfulactions = {};

    const currentDate = moment().subtract(filterAmountCurrent);
    const compareDate = moment().subtract(
      filterAmountCurrent + filterAmountCompare
    );

    if (school === "All Schools") {
      let addedStudents = [];
      Object.keys(districts[chosenDistrict]).forEach((school) => {
        districts[chosenDistrict][school].forEach((student) => {
          if (studentData[student] && !addedStudents.includes(student)) {
            addedStudents.push(student);
            studentData[student].forEach((datapoint) => {
              let datapointTimestamp = new Date(datapoint.date).valueOf();

              if (datapointTimestamp >= currentDate.valueOf()) {
                currentDict[datapoint.emotion] += 1;
                if (impactfulactions[datapoint.action] && datapoint.helpful)
                  impactfulactions[datapoint.action] += 1;
                else if (datapoint.helpful)
                  impactfulactions[datapoint.action] = 1;
              } else if (datapointTimestamp >= compareDate.valueOf())
                compareDict[datapoint.emotion] += 1;
            });
          }
        });
      });
    } else if (districts[chosenDistrict][school]) {
      let addedStudents = [];
      districts[chosenDistrict][school].forEach((student) => {
        if (studentData[student] && !addedStudents.includes(student)) {
          addedStudents.push(student);
          studentData[student].forEach((datapoint) => {
            let datapointTimestamp = new Date(datapoint.date).valueOf();

            if (datapointTimestamp >= currentDate.valueOf()) {
              currentDict[datapoint.emotion] += 1;
              if (impactfulactions[datapoint.action] && datapoint.helpful)
                impactfulactions[datapoint.action] += 1;
              else if (datapoint.helpful)
                impactfulactions[datapoint.action] = 1;
            } else if (datapointTimestamp >= compareDate.valueOf())
              compareDict[datapoint.emotion] += 1;
          });
        }
      });
    }
    transform(compareDict, currentDict);
    transformActions(impactfulactions);
    setChosenSchool(school);
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      var comparetotal = 0;
      counts.forEach((data) => {
        comparetotal += data.compare;
      });
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`} </p>
          <p className="label">{`current:`}</p>
          <p className="labelindent">
            {`count: ${payload[0].value}, percentage: ${Math.round(
              (payload[0].value / counter) * 100
            )}%`}{" "}
          </p>
          <p className="label">{`compare:`}</p>
          <p className="labelindent">
            {`count: ${payload[1].value}, percentage: ${Math.round(
              (payload[1].value / comparetotal) * 100
            )}%`}{" "}
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    
    <div class="outerbackground">
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
      <div class="columndatatrends">
        {/* <div class="datatrendstitle">Data Trends</div> */}
        {chosenDistrict === "Choose District" ? (
          <div class="practicedwellness">
            Choose a district to begin viewing data trends!
          </div>
        ) : (
          <div class="practicedwellness">
            Congratulations,{" "}
            <span class="greenlettersdata">{chosenDistrict}</span> practiced
            wellness <span class="greenlettersdata">{counter} times</span> in
            the last <span class="greenlettersdata">{chosenTime}</span>!
          </div>
        )}

        <div class="emotionsidentified">
          Emotions identified: this time period is in color, past is gray
        </div>
        <div class="bargraphdatatrends">
          <div class="labelrow">
            <div class="schoolrowsuper">District</div>
            <div class="classrowsuper">School</div>
            <div class="currentrowsuper">Current Data</div>
            <div class="previousrowsuper">Compare Data</div>
          </div>
          <select
            class="filterprofiledatasuper"
            value={chosenDistrict}
            onChange={(e) => { changeDistrictFilter(e.target.value); gatherWellnessPractices(feeling, chosenTime, e.target.value); }}
          >
            <option value="Choose District">Choose District</option>
            <option value="All Districts">All Districts</option>
            {districts&&
              Object.keys(districts).map((districtname) => (
                <option value={districtname}>{districtname}</option>
              ))}
          </select>{" "}
          <select
            class="filterprofiledatasuper"
            value={chosenSchool}
            onChange={(e) => changeSchoolFilter(e.target.value)}
          >
            <option value="All Schools">All Schools</option>
            {districts[chosenDistrict] &&
              chosenDistrict !== "Choose District" &&
              chosenDistrict !== "All Districts" &&
              Object.keys(districts[chosenDistrict]).map((schools) => (
                <option value={schools}>{schools}</option>
              ))}
          </select>{" "}
          <select
            class="filterprofiledatasuper"
            value={chosenTime}
            onChange={(e) => { changeTimeFilter(e.target.value); gatherWellnessPractices(feeling, e.target.value, chosenDistrict) }}
          >
            <option value="day">Today (Last 24 hours)</option>
            <option value="week">Week (Last 7 days)</option>
            <option value="month">Month (Last 30 days)</option>
            <option value="3 months">3 Months (Last 3 months)</option>
            <option value="6 months">6 Months (Last 6 months)</option>
            <option value="year">1 Year (Last 12 months)</option>
          </select>{" "}
          <select
            class="filterprofiledatasuper"
            value={chosenTimeCompare}
            onChange={(e) => changeTimeFilterCompare(e.target.value)}
          >
            <option value="day">Previous day</option>
            <option value="week">Previous week</option>
            <option value="month">Previous Month</option>
            <option value="3 months">Previous 3 Months</option>
            <option value="6 months">Previous 6 Months</option>
            <option value="year">Previous Year</option>
          </select>{" "}
          <ResponsiveContainer width="90%" height="80%">
            <BarChart className="datatrendsbarchart" data={counts}>
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "lightgray", fillOpacity: "30%" }}
              />
              <Bar barSize={50} dataKey="current">
                {counts.map((entry, index) => (
                  <Cell key={index} fill={barColors[Math.floor(index / 3)]} />
                ))}
              </Bar>
              <Bar barSize={50} dataKey="compare">
                {counts.map((entry, index) => (
                  <Cell key={index} fill={barColorsGray[0]} />
                ))}
              </Bar>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="name"
                minTickGap={-2}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        {Object.keys(threeImpactfulActions).length !== 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div class="datatrendsrow">
              <div class="datatrendscolumn1">
                {/* <div class="emotionsidentified"> */}
                The most impactful wellness practices for your students in the
                last <span class="greenlettersdata">{chosenTime}</span> are:{" "}
                {/* </div> */}
              </div>
              <div class="datatrendscolumn2">
                {Object.keys(threeImpactfulActions).map((action, ind) => (
                  <div class="wellnesspractices">
                    {ind + 1}. {action}, which was used{" "}
                    {threeImpactfulActions[action]} time(s)
                  </div>
                ))}
              </div>
            </div>

            <div className="practicedwellness" style={{ marginLeft: '0vw' }}>
              <span>When</span>
              <select
                class="filterprofiledatasuper"
                value={chosenDistrict}
                onChange={(e) => { changeDistrictFilter(e.target.value); gatherWellnessPractices(feeling, chosenTime, e.target.value) }}
              >
                <option value="Choose District">Choose District</option>
                <option value="All Districts">All Districts</option>
                {districts &&
                  Object.keys(districts).map((districtname) => (
                    <option value={districtname}>{districtname}</option>
                  ))}
              </select>
              <span>has felt</span>
              <select className="filterprofile" onChange={(e) => { gatherWellnessPractices(e.target.value, chosenTime, chosenDistrict) }} value={feeling}>
                <option value={"select"}>Select</option>
                <option value={"sad"}>Sad</option>
                <option value={"tired"}>Tired</option>
                <option value={"lonely"}>Lonely</option>
                <option value={"happy"}>Happy</option>
                <option value={"grateful"}>Grateful</option>
                <option value={"calm"}>Calm</option>
                <option value={"stressed"}>Stressed</option>
                <option value={"scared"}>Scared</option>
                <option value={"angry"}>Angry</option>
              </select>
              {(chosenTime === "3 months" || chosenTime === "6 months" || chosenTime === "year") && <span style={{ marginLeft: '2vw' }}> the</span>}
              <select className="filterprofile" onChange={(e) => { gatherWellnessPractices(feeling, e.target.value, chosenDistrict); changeTimeFilter(e.target.value) }} value={chosenTime}>
                <option value={"day"}>Today (24 Hours)</option>
                <option value={"week"}>This Week (7 days)</option>
                <option value={"month"}>This Month (30 days)</option>
                <option value={"3 months"}>Last 3 Months</option>
                <option value={"6 months"}>Last 6 Months</option>
                <option value={"year"}>Last Year</option>
              </select>
              <span>, wellness practices that have helped include {wellnessList.map((activity, key) => (<div className="greenlettersdata" key={key}>{activity}</div>))}</span>
            </div>
          </div>
        ) : (
          <div class="datatrendsempty">
            This class doesn't have a wellness history yet. Time to get started
            on the 1-2-3 Wellness journey!
          </div>
        )}
      </div>
    </div>
  );
};

export default WellnessDataTrendsScreen;
