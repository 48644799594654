import "./components.css"



export default function EmailButton() {
    return (
        <div id="emailLoginButton">
            <img id="emailImage" src="/images/emailLogo.png" loading="lazy"/>
            Sign In With Email
        </div>
    )
}
