import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../ProfileScreen/ProfileCalendar.css";
import "./ClassScreen.css";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../realm/user.context";
import { loadClasses, loadSchool, loadWellnesses, loadProfiles } from "../../../realm/graphqlQueries";
import { CircularProgress } from "@mui/material";



const ClassScreen = (props) => {
  const {realmUser} = useContext(UserContext)
  const [counts, setCounts] = useState({
    sad: 0,
    tired: 0,
    lonely: 0,
    happy: 0,
    grateful: 0,
    calm: 0,
    stressed: 0,
    scared: 0,
    angry: 0,
  });
  const [curStudentData, setCurStudentData] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [chosenClass, setChosenClass] = useState("");
  const [chosenStudent, setChosenStudent] = useState("");
  const [chosenTime, setChosenTime] = useState("week");
  const [wellnessData,setWellnessData] = useState({})
  const [affectedStudents, setAffectedStudents] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [publicStudentList, setPublicStudentList] = useState({});
  const [loading, setLoading] = useState(true);


  const navigate = useNavigate();
  function nav() {
    navigate("/studentsscreen");
  }

  var [tally, setTally] = useState(0);

  const [filterAmount, setFilterAmount] = useState(moment.duration(7, "days"));
  const monthAmount = moment.duration(31,"days")
  const [feeling, setFeeling] = useState("select");
  const [wellnessList, setWellnessList] = useState([]);
  const [percent, setPercent] = useState(0);
  const [state, setState] = useState({
    favourites: false,
    sad: true,
    tired: true,
    lonely: true,
    happy: true,
    grateful: true,
    calm: true,
    stressed: true,
    scared: true,
    angry: true,
  });
  const {
    favourites,
    sad,
    tired,
    lonely,
    happy,
    grateful,
    calm,
    stressed,
    scared,
    angry,
  } = state;
  const barColors = ["#5b9cd6", "#4cc68d", "#bd92e4"];

  function transform() {
    const arr = [];
    // console.log(counts)
    for (const val in counts) {
      arr.push({ name: val, count: counts[val] });
    }
    // console.log(arr, "This is the arr")
    return arr;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  // get all relevant data for this page
  // this functions sets the value of function
  // state allWellnessPoints, studentData, classes, and allCounts
  async function getAllData() {
    setLoading(true)
    let lclasses = []
    let dataObject = {}
    if(props.user.user.user==="teacher"){
      lclasses=props.user.user.class
    }else if (props.user.user.user==="admin"){
      const schoolRef = await loadSchool({name:props.user.user.school,district:props.user.user.district},realmUser)
      lclasses=schoolRef.school.classes
    }
    const classRef = await loadClasses(props.user.user.school,lclasses,realmUser)
    let studentList = []
    classRef.classes.forEach(cls=>{
      dataObject[cls.name] = cls
      studentList = studentList.concat(cls.students)
    })
    //make Union
    const allStudents = [...new Set(studentList)]
    //Get All Wellnessdata for students.
    const studentRef = await loadProfiles(allStudents,realmUser)
    const allStudentIDs = studentRef.users.map(x=>x._id)
    let wellnessRef = await loadWellnesses({userID_in:allStudentIDs},realmUser)
    //TODO - make sure date format is consistent then this is no longer necessary
    wellnessRef.wellnesses.forEach(datapoint=>{
      datapoint.date = Date.parse(datapoint.date)
    })
    let studentdata = {}
    let studentListPublic = []
    studentRef.users.forEach(student=>{
      if(student.dataUse===true){
        studentListPublic[student.email]=true
      }
      let relWellness = wellnessRef.wellnesses.filter(x=>x.userID===student._id)
      //console.log("Here is the way ",relWellness)
      if(relWellness===null || relWellness===undefined){
        studentdata[student.email]=[]
      }else{
      studentdata[student.email]=relWellness
      }

    })


    //console.log(studentdata)
    Object.keys(dataObject).forEach(classname=>{
      let sArray = dataObject[classname]["students"]
      //console.log(classname,sArray)
      //Generates an object {studentemail1:[w1,w2,w3], studentemail2:[w4,w5]...}
      function getStudentData(v){
        if(studentdata[v]){
          return studentdata[v]
        }
        else{
          return []
        }
      }
      let z = sArray.reduce((a, v) => ({ ...a, [v]: getStudentData(v)}), {}) 
      //console.log(z)
      dataObject[classname]["students"]=z
    })
    //console.log("completed data fetching",dataObject,allwellnesspoints)
    setWellnessData(dataObject)
    //console.log(studentdata)
    setPublicStudentList(studentListPublic)
    setStudentData(studentdata);
    setLoading(false)

  }
  function timeHelper(time) {
    if (time === "day") return moment.duration(24, "hours");
    if (time === "week") return moment.duration(7, "days");
    if (time === "month") return moment.duration(30, "days");
    if (time === "3 months") return moment.duration(90, "days");
    if (time === "6 months") return moment.duration(182, "days");
    if (time === "year") return moment.duration(365, "days");
  }

  function gatherWellnessPractices(feeling, time, selectedClass, studentSelect) {
    //console.log("the selected class:", selectedClass)
    //console.log("this is the time:", time)
    //console.log("this is the student", studentSelect)
    setChosenTime(time)
    if (studentSelect !== "") {
      setChosenStudent(studentSelect)
    }
    setChosenClass(selectedClass)
    const filter = timeHelper(time);
    const currentDate = moment().subtract(filter)

    var emotionDict = {
      "sad": {},
      "tired": {},
      "lonely": {},
      "happy": {},
      "grateful": {},
      "calm": {},
      "stressed": {},
      "scared": {},
      "angry": {}
    }
    function evaluateDatapoint(datapoint){
      if (datapoint.date >= currentDate.valueOf()) {
        let feeling = datapoint.emotion
        let helped = datapoint.helpful
        let action = datapoint.action

        if (helped) {
          if (! (action in emotionDict[feeling] )) {
            emotionDict[feeling][action] = 0
          }
          emotionDict[feeling][action] ++;
        }
      }
    }
    if (selectedClass === "Choose Class" || selectedClass === "") {
      setWellnessList("")
    } else if (studentSelect === "All Students" || studentSelect === "") {
      for (const val of Object.values(wellnessData[selectedClass]["students"])){
        val.map(datapoint=>evaluateDatapoint(datapoint))
      }
    } else {
      wellnessData[selectedClass]["students"][studentSelect].map(datapoint=>evaluateDatapoint(datapoint))
    }
    
    var feelingList;
    setFeeling(feeling)
    if (feeling === "select") {
      feelingList = [];
    } else {
      feelingList = Array.from(Object.keys(emotionDict[feeling]));
    }
    if (feelingList.length > 3) {
      let objectList = Object.keys(emotionDict[feeling]).sort(function(a,b){return emotionDict[feeling][b] - emotionDict[feeling][a]})
      setWellnessList(objectList.slice(0, 3))
    } else {
      setWellnessList(feelingList)
    }
  }

  function changeClassFilter(classname) {
    setChosenStudent("All Students");
    setTally(0)
    var dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let curstudentdata = [];
    let monthdata = []
    if (classname !== "Choose Class" && classname !== "All Classes") {
      for (const value of Object.values(wellnessData[classname]["students"])) {
        //console.log(key,value)
        value.filter(
          (data) => data.date >= moment().subtract(filterAmount).valueOf()
        )
        .forEach((datapoint) => {
          //console.log(datapoint)
          dict[datapoint.emotion] += 1;
          setTally(tally => tally + 1)
          curstudentdata.push(datapoint);
        })
        value.filter(
          (data) => data.date >= moment().subtract(monthAmount).valueOf()
        )
        .forEach((datapoint) => {monthdata.push(datapoint)});
      }
    }
    setCounts(dict);
    setCurStudentData(curstudentdata);
    setChosenClass(classname);
    setMonthData(monthdata)
  }
  function changeStudentFilter(studentemail) {
    setTally(0)
    if (studentemail === "All Students") {
      //setCurStudentData(allWellnessPoints);
      changeClassFilter(chosenClass);
      setChosenStudent(studentemail);
      return;
    }
    let curstudentdata = [] 
    console.log("chosenClass is", chosenClass, curstudentdata)

    var dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let monthdata = []
    //console.log(wellnessData[chosenClass]["students"][studentemail],moment().subtract(filterAmount).valueOf())
    if(wellnessData[chosenClass]["students"][studentemail]!==undefined){
      curstudentdata = wellnessData[chosenClass]["students"][studentemail]
    wellnessData[chosenClass]["students"][studentemail]
      .filter((data) => data.date >= moment().subtract(filterAmount).valueOf())
      .forEach((datapoint) => {
        //console.log(datapoint)
        dict[datapoint.emotion] += 1; setTally(tally => tally + 1)
      });
      wellnessData[chosenClass]["students"][studentemail]
      .filter((data) => data.date >= moment().subtract(monthAmount).valueOf())
      .forEach((datapoint) => {
        monthdata.push(datapoint)
      });
    }
    setCurStudentData(curstudentdata);
    setMonthData(monthdata)  
    setChosenStudent(studentemail);
    setCounts(dict);
  }
  function changeTimeFilter(time) {
    setTally(0);
    var filter = moment.duration(7, "days");
    var curstudentdata = [];
    var dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    if (time === "day") {
      filter = moment.duration(24, "hours");
    } else if (time === "week") {
      filter = moment.duration(7, "days");
    } else if (time === "month") {
      filter = moment.duration(30, "days");
    } else if (time === "3 months") {
      filter = moment.duration(90, "days");
    } else if (time === "6 months") {
      filter = moment.duration(182, "days");
    } else if (time === "year") {
      filter = moment.duration(365, "days");
    }
    if (chosenStudent === "All Students") {
      for (const value of Object.values(wellnessData[chosenClass]["students"])){
        value.filter((data) => data.date >= moment().subtract(filter).valueOf())
        .forEach((datapoint) => {
          dict[datapoint.emotion] += 1;
          console.log("changing the tally")
          setTally(tally => tally + 1);
          curstudentdata.push(datapoint);
        })
      }
    }
      else {
      wellnessData[chosenClass]["students"][chosenStudent]
        .filter((data) => data.date >= moment().subtract(filter).valueOf())
        .forEach((datapoint) => {
          dict[datapoint.emotion] += 1;
          setTally(tally => tally + 1);
          curstudentdata.push(datapoint);
        });
    }
    console.log("emotion dict", dict, tally)
    setChosenTime(time);
    setFilterAmount(filter);
    setCurStudentData(curstudentdata);
    setCounts(dict);
  }

  function findStudents(feeling, time, selectedClass, percent) {
    //console.log("Finding students: ", feeling)
    setChosenTime(time);
    setFeeling(feeling)
    setPercent(percent)
    setChosenClass(selectedClass)
    const filter = timeHelper(time);
    const currentDate = moment().subtract(filter)

    let studentList = []
    let studentSource = null
    if(feeling!=="" && feeling !== "select"){
    if (selectedClass === "Choose Class" || selectedClass === "") {
      studentSource=studentData
    }
    else{
      studentSource=wellnessData[selectedClass]["students"]
    }
    //console.log(studentSource)
    for (const [student,data] of Object.entries(studentSource)){
          let totalCount = 0;
          let emotionCount = 0;
          data.forEach((datapoint) => {
            if (datapoint.date >= currentDate.valueOf()) {
              let emotion = datapoint.emotion
              totalCount ++;
              if (emotion === feeling) {
                emotionCount++;
              }
            }
          })
          //Note if the student has any wellness entry and the percent is set at 0%, then this will be true
          if (publicStudentList[student]&&emotionCount / totalCount >= percent && publicStudentList[student]&&emotionCount / totalCount>0) {
            studentList.push(student)
      }
      }
    }
    setAffectedStudents(studentList)
  }
  const events = monthData.map((data) => {
    return {
      start: moment(data.date).toDate(),
      end: moment(data.date).add(1, "minutes").toDate(),
      title: (
        <img
          key={data.date}
          className="calendarimg"
          src={getImage(data.emotion)}
          alt="...loading"
          loading="lazy"
        ></img>
      ),
    };
  });
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    getAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("the tally changed", tally);
  // }, [tally]);

  const CustomTooltip = ({ active, payload, label }) => {
    var total = 0;
    Object.keys(counts).forEach((data) => {
      total += counts[data];
    });
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`} </p>
          <p className="bluelabel">{`count: ${payload[0].value}`} </p>
          <p className="bluelabel">{`percentage : ${Math.round(
            (payload[0].value / total) * 100
          )}%`}</p>
          <p className="bluelabel">{`total : ${total}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div
      className="backgroundlayer"
      // style={{
      //   // display: "flex",
      //   // flexDirection: "row",
      //   backgroundColor: "#f8f2ee",
      //   height:'100vh'
      // }}
    >
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
      <div className="profileoutercolumn">
        <div className="titletext">Bar Graph</div>
        {chosenClass === "" || chosenClass === "Choose Class" ? (
          <div className="titledescription">
            This bar chart shows the emotions your class has been feeling
          </div>
        ) : (
          // <div className="titledescription">
          //   Viewing {chosenClass} for {chosenStudent} in the last {chosenTime}
          // </div>
          <div className="practicedwellness" style={{marginLeft: '8vw'}}>
            Congratulations,{" "} <b>{chosenStudent}</b> in class:
            <span className="greenlettersdata"> {chosenClass}</span> practiced
            wellness <span className="greenlettersdata">{tally} times</span> in
            the last <span className="greenlettersdata">{chosenTime}</span>!
          </div>
        )}
        <div className="bargraph">
          <select
            className="filterprofile"
            value={chosenClass}
            onChange={(e) => {changeClassFilter(e.target.value); findStudents(feeling, chosenTime, e.target.value, percent); 
            gatherWellnessPractices(feeling, chosenTime, e.target.value, "All Students")}}
          >
            <option value="Choose Class">Choose Class</option>
            {Object.keys(wellnessData).map((classname) => (
              <option key={classname} value={classname}>{classname}</option>
            ))}
          </select>{" "}
          <select
            className="filterprofile"
            value={chosenStudent}
            onChange={(e) => {
              //console.log("selected", e.target.value);
            changeStudentFilter(e.target.value); 
              gatherWellnessPractices(feeling, chosenTime, chosenClass, e.target.value)}}
          >
            <option value="All Students">All Students</option>
            {chosenClass !== "" &&
              chosenClass !== "Choose Class" &&
              Object.keys(wellnessData[chosenClass]["students"]).filter(x=>publicStudentList[x]).map((student) => (
                <option key={student} value={student}>{student}</option>
              ))}
          </select>{" "}
          <select
            className="changetimefilter"
            value={chosenTime}
            onChange={(e) => {changeTimeFilter(e.target.value); findStudents(feeling, e.target.value, chosenClass, percent); gatherWellnessPractices(feeling, e.target.value, chosenClass, chosenStudent)}}
          >
            <option value={"day"}>Today (24 Hours)</option>
            <option value={"week"}>This Week (7 days)</option>
            <option value="month">Month (This Month)</option>
            <option value="3 months">3 Months (Last 3 months)</option>
            <option value="6 months">6 Months (Last 6 months)</option>
            <option value="year">1 Year (Last 12 months)</option>
          </select>{" "}
          <ResponsiveContainer width="90%" height="80%">
            <BarChart
              style={{ marginLeft: "3vw", marginTop: "6vh", fontSize: "1vw" }}
              data={transform()}
            >
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "lightgray", fillOpacity: "30%" }}
              />
              <Bar barSize={50} dataKey="count" fill="#5b9cd6">
                {transform().map((entry, index) => (
                  <Cell key={index} fill={barColors[Math.floor(index / 3)]} />
                ))}
              </Bar>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="name"
                minTickGap={-2}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="practicedwellness" style={{marginLeft: '8vw'}}>
          <div className="titletext" style={{marginBottom: '10px', marginLeft: '0px'}}>Which Students</div>
          <div style={{backgroundColor: 'white', width: '63vw', height: '50vh'}}>
            <div>
              <span>Show me the students in </span>
              <select
              className="filterprofile"
              value={chosenClass}
              onChange={(e) => {changeClassFilter(e.target.value); findStudents(feeling, chosenTime, e.target.value, percent); gatherWellnessPractices(feeling, chosenTime, e.target.value, "All Students")}}
              >
                <option value="Choose Class">All Classes</option>
                {Object.keys(wellnessData).map((classname) => (
                  <option key={classname} value={classname}>{classname}</option>
                ))}
              </select>
            
              <span style={{marginLeft: '2vw'}}>who felt</span>
              <select className="filterprofile" onChange={(e) => {findStudents(e.target.value, chosenTime, chosenClass, percent); gatherWellnessPractices(e.target.value, chosenTime, chosenClass, chosenStudent)}} value={feeling}>
                <option value={"select"}>Select</option>
                <option value={"sad"}>Sad</option>
                <option value={"tired"}>Tired</option>
                <option value={"lonely"}>Lonely</option>
                <option value={"happy"}>Happy</option>
                <option value={"grateful"}>Grateful</option>
                <option value={"calm"}>Calm</option>
                <option value={"stressed"}>Stressed</option>
                <option value={"scared"}>Scared</option>
                <option value={"angry"}>Angry</option>
              </select>
            </div>
            {(chosenTime === "3 months" || chosenTime === "6 months" || chosenTime === "year" ) && <span style={{marginLeft: '2vw'}}> the</span>}
            <select
              className="changetimefilter"
              value={chosenTime}
              onChange={(e) => {findStudents(feeling, e.target.value, chosenClass, percent); changeTimeFilter(e.target.value); gatherWellnessPractices(feeling, e.target.value, chosenClass, chosenStudent)}}
              style={{marginRight: '0px'}}
            >
              <option value={"day"}>Today (24 Hours)</option>
              <option value={"week"}>This Week (7 days)</option>
              <option value="month">This Month</option>
              <option value="3 months">Last 3 months</option>
              <option value="6 months">Last 6 months</option>
              <option value="year">Last year</option>
            </select>
            <span style={{marginLeft: '2vw'}}>over</span>
            <select
              className="changetimefilter"
              value={percent}
              onChange={(e) => {findStudents(feeling, chosenTime, chosenClass, e.target.value);}}
              style={{marginRight: '0px'}}
            >
              <option value={0.0}>Anytime</option>
              <option value={0.10}>10% of the time</option>
              <option value={0.25}>25% of the time</option>
              <option value={0.50}>50% of the time</option>
              <option value={0.75}>75% of the time</option>
              <option value={1}>100% of the time</option>
            </select>
            <div style={{overflowY: 'scroll', height: '35vh'}}>
              {affectedStudents.map((student, key) => (<div className="greenlettersdata" key={key}>{student}</div>))}
            </div>
          </div>
        </div>
        <div className="practicedwellness" style={{marginLeft: '8vw'}}>
          <div className="titletext" style={{marginLeft: '0px'}}>What Helps</div>
          <span>When</span>
          <select
            className="filterprofile"
            value={chosenStudent}
            onChange={(e) => {changeStudentFilter(e.target.value); gatherWellnessPractices(feeling, chosenTime, chosenClass, e.target.value);}}
          >
            <option value="All Students">All Students</option>
            {chosenClass !== "" &&
              chosenClass !== "Choose Class" &&
              Object.keys(wellnessData[chosenClass]["students"]).filter(x=>publicStudentList[x]).map((student) => (
                <option key={student} value={student}>{student}</option>
              ))}
          </select>
          <span style={{marginLeft: '2vw'}}>in</span>
          <select
            className="filterprofile"
            value={chosenClass}
            onChange={(e) => {changeClassFilter(e.target.value); gatherWellnessPractices(feeling, chosenTime, e.target.value, "All Students"); findStudents(feeling, chosenTime, e.target.value, percent)}}
          >
            <option value="Choose Class">Choose Class</option>
            {Object.keys(wellnessData).map((classname) => (
              <option key={classname} value={classname}>{classname}</option>
            ))}
          </select>
          <span style={{marginLeft: "2vw"}}>has felt</span>
          <select className="filterprofile" onChange={(e) => {gatherWellnessPractices(e.target.value, chosenTime, chosenClass, chosenStudent); findStudents(e.target.value, chosenTime, chosenClass, percent)}} value={feeling}>
            <option value={"select"}>Select</option>
            <option value={"sad"}>Sad</option>
            <option value={"tired"}>Tired</option>
            <option value={"lonely"}>Lonely</option>
            <option value={"happy"}>Happy</option>
            <option value={"grateful"}>Grateful</option>
            <option value={"calm"}>Calm</option>
            <option value={"stressed"}>Stressed</option>
            <option value={"scared"}>Scared</option>
            <option value={"angry"}>Angry</option>
          </select>
          {(chosenTime === "3 months" || chosenTime === "6 months" || chosenTime === "year" ) && <span style={{marginLeft: '2vw'}}> the</span>}
          <select className="filterprofile" onChange={(e) => {gatherWellnessPractices(feeling, e.target.value, chosenClass, chosenStudent); changeTimeFilter(e.target.value); findStudents(feeling, e.target.value, chosenClass, percent)}} value={chosenTime}>
            <option value={"day"}>Today (24 Hours)</option>
            <option value={"week"}>This Week (7 days)</option>
            <option value={"month"}>This Month (30 days)</option>
            <option value={"3 months"}>Last 3 Months</option>
            <option value={"6 months"}>Last 6 Months</option>
            <option value={"year"}>Last Year</option>
          </select>
          <span>, wellness practices that have helped include {wellnessList.map((activity, key) => (<div className="greenlettersdata" key={key}>{activity}</div>))}</span>
        </div>
        <div className="titletext">Wellness History</div>
        {chosenClass === "" || chosenClass === "Choose Class" ? (
          <div className="titledescription">
            Your classes wellness history shows their emotions and which
            wellness practices are most helpful
          </div>
        ) : (
          <div className="titledescription">
            Viewing {chosenStudent} in class: {chosenClass} in the last {chosenTime}
          </div>
        )}
        <div className="profilecolumn">
          <div className="dropwdownrow">
            <select
              className="filterprofile"
              value={chosenClass}
              onChange={(e) => {changeClassFilter(e.target.value); findStudents(feeling, chosenTime, e.target.value, percent); gatherWellnessPractices(feeling, chosenTime, e.target.value, "All Students")}}
            >
              <option value="Choose Class">Choose Class</option>
              {Object.keys(wellnessData).map((classname) => (
              <option key={classname} value={classname}>{classname}</option>
            ))}
            </select>{" "}
            <select
              className="filterprofile"
              value={chosenStudent}
              onChange={(e) => {changeStudentFilter(e.target.value); gatherWellnessPractices(feeling, chosenTime, chosenClass, e.target.value)}}
            >
              <option value="All Students">All Students</option>
              {chosenClass !== "" &&
                chosenClass !== "Choose Class" &&
                Object.keys(wellnessData[chosenClass]["students"]).filter(x=>publicStudentList[x]).map((student) => (
                  <option key={student} value={student}>{student}</option>
                ))}
            </select>{" "}
            <select
              className="changetimefilter"
              value={chosenTime}
              onChange={(e) => {changeTimeFilter(e.target.value); findStudents(feeling, e.target.value, chosenClass, percent); gatherWellnessPractices(feeling, e.target.value, chosenClass, chosenStudent)}}
            >
              <option value={"day"}>Today (24 Hours)</option>
              <option value={"week"}>This Week (7 days)</option>
              <option value="month">Month (This Month)</option>
              <option value="3 months">3 Months (Last 3 months)</option>
              <option value="6 months">6 Months (Last 6 months)</option>
              <option value="year">1 Year (Last 12 months)</option>
            </select>
            <button className="demo-customized-button-class" onClick={handleClick}>
              Filter Options
            </button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                {/* <FormLabel component="legend">Assign responsibility</FormLabel>*/}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={favourites}
                        onChange={handleChange}
                        name="favourites"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <FaHeart
                          // src="images/emotions/angry.png"
                          style={{ width: "2vw", marginRight: "5px" }}
                        />
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sad}
                        onChange={handleChange}
                        name="sad"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                      <div className="emojiDropdown">
                        <span style={{marginRight: '5px'}}>Sad</span>
                        <img
                          alt="sad-icon"
                            src="images/emotions/sad.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                          loading="lazy"
                        />
                      </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tired}
                        onChange={handleChange}
                        name="tired"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Tired</span>
                          <img
                              alt="lazy-icon"
                              src="images/emotions/tired.png"
                              style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lonely}
                        onChange={handleChange}
                        name="lonely"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Lonely</span>
                          <img
                          alt="lonely-icon"
                            src="images/emotions/lonely.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={happy}
                        onChange={handleChange}
                        name="happy"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Happy</span>
                          <img
                          alt="happy-icon"
                            src="images/emotions/happy.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={grateful}
                        onChange={handleChange}
                        name="grateful"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Grateful</span>
                          <img
                          alt="grateful-icon"
                            src="images/emotions/grateful.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={calm}
                        onChange={handleChange}
                        name="calm"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Calm</span>
                          <img
                          alt="calm-icon"
                            src="images/emotions/calm.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stressed}
                        onChange={handleChange}
                        name="stressed"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Stressed</span>
                          <img
                          alt="stressed-icon"
                            src="images/emotions/stressed.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={scared}
                        onChange={handleChange}
                        name="scared"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Scared</span>
                          <img
                          alt="scared-icon"
                            src="images/emotions/scared.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                        
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={angry}
                        onChange={handleChange}
                        name="angry"
                      />
                    }
                    labelPlacement="start"
                    label={
                      <>
                        <div className="emojiDropdown">
                          <span style={{marginRight: '5px'}}>Angry</span>
                          <img
                          alt="angry-icon"
                            src="images/emotions/angry.png"
                            style={{ width: "2vw", marginRight: "5px" }}
                            loading="lazy"
                          />
                        </div>
                        
                      </>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Menu>
          </div>
          <div className="wellnesspoints">
            {chosenStudent !== "All Students" &&
              curStudentData
                .sort((a, b) => b.date - a.date)
                .filter(
                  (data) =>
                    data.date >= moment().subtract(filterAmount).valueOf() &&
                    state[data.emotion] === true &&
                    checkFavorites(data.helpful)
                )
                .map((data) => (
                  <div
                    className="historybox"
                    key={data.date}
                    style={{ backgroundColor: getColor(data.emotion) }}
                  >
                    <img
                      className="profileimg"
                      src={getImage(data.emotion)}
                      alt="...loading"
                      loading="lazy"
                    ></img>
                    {/* <div className='profileemotion'>{data.emotion}</div>*/ }
                    <div className="profileaction">{data.action}</div>
                    <div className="profilefeelbetter">
                      {getHeart(data.helpful)}
                      <div>Feel better?</div>
                    </div>
                    <div className="profiletime">{getTime(data.date)}</div>
                    <div className="profiledate">{getDate(data.date)}</div>
                  </div>
                ))}

            {
            chosenStudent === "All Students" &&
              chosenClass !== "Choose Class" && chosenClass !== "" &&
              Object.keys(wellnessData[chosenClass]["students"]).filter(student=>studentData[student]!==undefined).map((student) =>
                studentData[student]
                  .sort((a, b) => b.date - a.date)
                  .filter(
                    (data) =>
                      data.date >= moment().subtract(filterAmount).valueOf() &&
                      state[data.emotion] === true &&
                      checkFavorites(data.helpful)
                  )
                  .map((data) => (
                    <div
                      className="historybox"
                      key={data.date}
                      style={{ backgroundColor: getColor(data.emotion) }}
                    >
                      <img
                        className="profileimg"
                        src={getImage(data.emotion)}
                        alt="...loading"
                        loading="lazy"
                      ></img>
                      {//<div className='profileemotion'>{data.emotion}</div>
                      }
                      <div className="profileaction">{data.action}</div>
                      <div className="profilefeelbetter">
                        {getHeart(data.helpful)}
                        <div>Feel better?</div>
                      </div>
                      <div className="profiletime">{getTime(data.date)}</div>
                      <div className="profiledate">{getDate(data.date)}</div>
                    </div>
                  ))
              )
              }
          </div>
        </div>{" "}
        <div className="titletext">Calendar</div>
        {chosenClass === "" || chosenClass === "Choose Class" ? (
          <div className="titledescription">
            This calendar shows the emotions a student has felt this month
          </div>
        ) : (
          <div className="titledescription">
            Viewing data of {chosenStudent} in class: {chosenClass} for  in this month
          </div>
        )}
        <div className="profilecalendar">
        <select
            className="filterprofile"
            value={chosenClass}
            onChange={(e) => {
              changeClassFilter(e.target.value); 
              findStudents(feeling, chosenTime, e.target.value, percent); 
              gatherWellnessPractices(feeling, chosenTime, e.target.value, "All Students")}}
          >
            <option value="Choose Class">Choose Class</option>
            {Object.keys(wellnessData).map((classname) => (
              <option key={classname} value={classname}>{classname}</option>
            ))}
          </select>{" "}
          <select
            className="filterprofile"
            value={chosenStudent}
            onChange={(e) => {console.log("selected", e.target.value);changeStudentFilter(e.target.value);
              gatherWellnessPractices(feeling, chosenTime, chosenClass, e.target.value)}}
          >
            <option value="All Students">All Students</option>
            {chosenClass !== "" &&
              chosenClass !== "Choose Class" &&
              Object.keys(wellnessData[chosenClass]["students"]).filter(x=>publicStudentList[x]).map((student) => (
                <option key={student} value={student}>{student}</option>
              ))}
          </select>{" "}
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={events}
            className="calendarsizer"
            // style={{ height: "85vh", width: "55vw" }}
          />
        </div>
        <div className="studentsbuttonrow" style={{marginBottom: '40px'}}>
          <button className="seeclassdata" onClick={() => nav()}>
            Add Students/Staff
          </button>
        </div>
      </div>
    </div>
  );

  function getDate(data) {
    return moment(data).format("l");
  }
  function getTime(data) {
    return moment(data).format("LT");
  }
  function getColor(emotion) {
    if (emotion === "happy" || emotion === "grateful" || emotion === "calm")
      return "#A5E2C6";
    else if (
      emotion === "stressed" ||
      emotion === "scared" ||
      emotion === "angry"
    )
      return "#DEC8F1";
    else if (emotion === "sad" || emotion === "tired" || emotion === "lonely")
      return "#ADCDEA";
  }
  function getImage(emotion) {
    return "../images/emotions/" + emotion + ".png";
  }
  function getHeart(helpful) {
    if (helpful) return <FaHeart style={{ fontSize: "1.3vmax" }}></FaHeart>;
    else return <FaRegHeart style={{ fontSize: "1.3vmax" }}></FaRegHeart>;
  }
  function checkFavorites(emotion) {
    if (state.favourites === false) {
      return true;
    } else {
      return emotion;
    }
  }
};
export default ClassScreen;